import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template"]

  show(e) {
    const content = this.templateTarget.innerHTML
    const action = e.target.dataset.renderTemplateAction

    if (action == "replace") {
      this.element.innerHTML = content
    } else if (action == "append") {
      this.element.insertAdjacentHTML('beforeend', content)
    }
  }
}