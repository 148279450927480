export const TOOLBAR_ITEMS = [
  {
    name: 'bold',
    icon: null,
    component: 'BoldIcon',
    action: editor => editor.chain().focus().toggleBold().run(),
    isActive: editor => editor.isActive('bold')
  },
  {
    name: 'italic',
    icon: null,
    component: 'ItalicIcon',
    action: editor => editor.chain().focus().toggleItalic().run(),
    isActive: editor => editor.isActive('italic')
  },
  {
    name: 'strike',
    icon: null,
    component: 'StrikethroughIcon',
    action: editor => editor.chain().focus().toggleStrike().run(),
    isActive: editor => editor.isActive('strike')
  },
  {
    name: 'paragraph',
    icon: 'Paragraph',
    component: null,
    action: editor => editor.chain().focus().setParagraph().run(),
    isActive: editor => editor.isActive('paragraph')
  },
  {
    name: 'heading_1',
    icon: null,
    component: 'H1Icon',
    action: editor => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    isActive: editor => editor.isActive('heading', { level: 1 })
  },
  {
    name: 'heading_2',
    icon: null,
    component: 'H2Icon',
    action: editor => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    isActive: editor => editor.isActive('heading', { level: 2 })
  },
  {
    name: 'heading_3',
    icon: null,
    component: 'H3Icon',
    action: editor => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    isActive: editor => editor.isActive('heading', { level: 3 })
  },
  {
    name: 'bulletList',
    icon: null,
    component: 'ListBulletIcon',
    action: editor => editor.chain().focus().toggleBulletList().run(),
    isActive: editor => editor.isActive('bulletList')
  },
  {
    name: 'orderedList',
    icon: null,
    component: 'NumberedListIcon',
    action: editor => editor.chain().focus().toggleOrderedList().run(),
    isActive: editor => editor.isActive('orderedList')
  },
  {
    name: 'blockquote',
    icon: `<svg fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="h-4 w-4">
      <path d="M11.86,16.55a4.31,4.31,0,0,0-2.11.56,14.44,14.44,0,0,1,4.36-6,1.1,1.1,0,0,0-1.4-1.7c-4,3.25-5.78,7.75-5.78,10.54A5.08,5.08,0,0,0,10,24.58a4.4,4.4,0,0,0,1.88.44,4.24,4.24,0,1,0,0-8.47Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M23,16.55a4.29,4.29,0,0,0-2.11.56,14.5,14.5,0,0,1,4.35-6,1.1,1.1,0,1,0-1.39-1.7c-4,3.25-5.78,7.75-5.78,10.54a5.08,5.08,0,0,0,3,4.61A4.37,4.37,0,0,0,23,25a4.24,4.24,0,1,0,0-8.47Z" class="clr-i-outline clr-i-outline-path-2"></path>
      <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
    </svg>`,
    component: null,
    action: editor => editor.chain().focus().toggleBlockquote().run(),
    isActive: editor => editor.isActive('blockquote')
  },
  {
    name: "align_left",
    icon: null,
    component: 'Bars3BottomLeftIcon',
    action: editor => editor.chain().focus().setTextAlign('left').run(),
    isActive: editor => editor.isActive('align', { align: 'left' })
  },
  {
    name: "align_center",
    icon: null,
    component: 'Bars3Icon',
    action: editor => editor.chain().focus().setTextAlign('center').run(),
    isActive: editor => editor.isActive('align', { align: 'center' })
  },
  {
    name: "align_right",
    icon: null,
    component: 'Bars3BottomRightIcon',
    action: editor => editor.chain().focus().setTextAlign('right').run(),
    isActive: editor => editor.isActive('align', { align: 'right' })
  },
  {
    name: 'undo',
    icon: null,
    component: 'ArrowUturnLeftIcon',
    action: editor => editor.chain().focus().undo().run(),
    isActive: () => false
  },
  {
    name: 'redo',
    icon: null,
    component: 'ArrowUturnRightIcon',
    action: editor => editor.chain().focus().redo().run(),
    isActive: () => false
  },
]
