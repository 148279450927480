import { CONFIG } from '@/features/slides_editor/config/constants/slides'
import { nextTick, inject } from 'vue'
import { useBlockHelpers } from '@/features/slides_editor/composables'

const getSlideType = (slide) => {
  if (slide.blocks.some(block => block.has_spark)) {
    return 'spark'
  }

  if (slide.blocks.some(block => block.block_type === 'section')) {
    return 'section'
  }

  if (slide.blocks.length === 0) {
    return 'blank'
  }

  return 'default'
}

export function useSlideHelpers(slides, reveal) {
  const getConfig = (slide, featureFlags = {}) => {
    const slideType = getSlideType(slide)

    if (slideType === 'section' && !featureFlags.questions_crud) {
      return CONFIG.default
    }

    if (slideType === 'spark' && !featureFlags.edit_sparks) {
      return CONFIG.default
    }

    return CONFIG[getSlideType(slide)]
  }

  const syncSlidePositions = () => {
    slides.value = slides.value.map((slide, index) => ({
      ...slide,
      position: index + 1
    }))
  }

  const addSlide = (slide) => {
    slides.value.splice(slide.position - 1, 0, slide)
    syncSlidePositions()
    syncReveal()

    nextTick(() => {
      reveal.value?.slide(slide.position - 1)
    })
  }

  const removeSlide = (slide_id) => {
    slides.value = slides.value.filter(s => s.id !== slide_id)
    syncSlidePositions()
    syncReveal()
  }

  const { reconcileBlocks } = useBlockHelpers()

  const updateSlides = (updatedSlideData) => {
    const index = slides.value.findIndex(slide => slide.id === updatedSlideData.id)
    if (index !== -1) {
      const currentSlide = slides.value[index]
      const mergedBlocks = reconcileBlocks(currentSlide.blocks, updatedSlideData.blocks)
      slides.value[index] = {
        ...currentSlide,
        ...updatedSlideData,
        blocks: mergedBlocks
      }
      syncReveal()
    }
  }

  const syncReveal = () => {
    nextTick(() => {
      if (reveal.value) {
        reveal.value.sync()
      } else {
        console.error('Reveal instance not initialized')
      }
    })
  }

  return {
    getConfig,
    addSlide,
    removeSlide,
    updateSlides,
    syncSlidePositions
  }
}
