import { Controller } from '@hotwired/stimulus'
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

export default class extends Controller {
  static targets = ['input']
  static values = {
    currentFile: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    if (this.currentFileValue) {
      this.setupDummyInstance()
    } else {
      this.setupInstance()
    }
  }

  setupDummyInstance() {
    this.input.classList.add('sr-only')

    const html = `
      <div class="filepond--root filepond--hopper flex items-center px-4">
        <div class="flex w-full rounded text-white py-2 px-4 text-xs gap-3" style="background-color: #64605e;">
          <button data-action="filepond#removeCurrentFile filepond#setupInstance" class="filepond--file-action-button filepond--action-remove-item" type="button" data-align="left">
            <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z" fill="currentColor" fill-rule="nonzero">
              </path>
            </svg>
            <span>Remove</span>
          </button>
          <span class="flex items-center" aria-hidden="true">
            ${this.currentFileValue}
          </span>
        </div>
      </div>
    `

    this.element.insertAdjacentHTML('beforeend', html)
  }

  removeCurrentFile(event) {
    event.target.closest('.filepond--hopper').remove()
    this.input.classList.remove('sr-only')

    this.setupInstance()
  }

  setupInstance() {
    FilePond.registerPlugin(FilePondPluginFileValidateType)

    this.filePond = FilePond.create(this.input, {
      storeAsFile: true,
      required: this.requiredValue
    })
  }

  get input() {
    return this.element.querySelector('input[type="file"]')
  }
}
