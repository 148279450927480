import { Controller } from '@hotwired/stimulus'
import consumer from "../channels/consumer"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["stream"]
  static values = {
    protocol: {
      type: String,
      default: "wss"
    },
    url: String,
    lessonId: Number,
    classId: Number,
    signedStreamName: String,
    channel: String,
    currentActiveTime: {
      type: Number,
      default: 0
    },
    deviceFree: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    if (this.protocolValue === "wss") {
      this.subscribe()
    }

    this.start()
    this.setupVisibilityEvents()
  }

  subscribe() {
    this.subscription = consumer.subscriptions.create(this.channel, {
      connected() {
        console.log('Tracking connected...')
      },
      received(data) {
        console.log('Tracking received:', data)
      },
      disconnected() {
        console.log('Tracking disconnected...')
      }
    })
  }

  start() {
    this.focused = true
    this.lastActivity = Date.now()
    this.activeTime = 0
    this.visibilityState = document.visibilityState

    this.interval = setInterval(() => {
      if (this.visible) {
        const now = Date.now()
        const delta = now - this.lastActivity

        this.activeTime += delta
        this.lastActivity = now

        // Check if a full minute of active time has passed
        if (this.activeTime >= 60000) {
          this.track()

          // Reset active time
          this.activeTime = 0
        }
      } else {
        this.lastActivity = Date.now()
      }

      this.visibilityState = document.visibilityState
    }, 1000) // Check every second
  }

  setupVisibilityEvents() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible" && this.visibilityState === "hidden") {
        this.lastActivity = Date.now()
      }
    })

    window.addEventListener("blur", () => {
      this.focused = false
    })

    window.addEventListener("focus", () => {
      this.focused = true
      this.lastActivity = Date.now()
    })
  }

  track() {
    if (this.protocolValue === "wss") {
      this.subscription.send({
        type: "active_time",
        device_free: this.deviceFreeValue
      })
    } else if (this.protocolValue === "https") {
      post(this.urlValue)
    }
  }

  disconnect() {
    clearInterval(this.interval)
  }

  get channel() {
    const channel = this.channelValue
    const signed_stream_name = this.signedStreamNameValue

    return { channel, signed_stream_name }
  }

  get visible() {
    return document.visibilityState === "visible" && this.focused
  }
}
