import { Controller } from '@hotwired/stimulus'
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'

export default class extends Controller {
  static targets = ['content']

  connect() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      content: this.contentTarget.dataset.content,
      element: this.contentTarget,
      editable: this.contentTarget.dataset.editable === 'true'
    })
  }
}
