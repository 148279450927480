import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static values = {
    ...Sortable.values,
    delay: {
      type: Number,
      default: 0
    }
  }

  connect() {
    super.connect()
  }

  get options() {
    return {
      ...super.options,
      filter: '.disable-sorting',
      scrollSensitivity: 200,
      delay: this.delayValue,
      forceFallback: true
    }
  }
}