export const CONFIG = {
  blank: {
    side_panel: {
      fixed: true,
      content: (props = {}) => {
        return {
          title: 'Choose a layout',
          component: 'LayoutForm',
          props: { ...props }
        }
      }
    }
  },
  default: {
    side_panel: {
      fixed: false
    }
  },
  section: {
    side_panel: {
      fixed: true,
      content: (props = {}) => {
        return {
          title: 'Add Question',
          component: 'VariantPicker',
          props: { ...props }
        }
      }
    }
  },
  spark: {
    side_panel: {
      fixed: true,
      content: (props = {}) => {
        const block = props.slide.blocks.find(block => block.has_spark)

        if (block) {
          return {
            title: 'Edit Spark',
            component: 'SparkForm',
            props: { block }
          }
        }

        return {
          title: 'Add Question',
          component: 'VariantPicker',
          props: { ...props }
        }
      }
    },
  }
}
