import { inject, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export function useContentEditor(block) {
  const currentEditor = inject('currentEditor')
  const blockRef = ref(null)

  onClickOutside(blockRef, (event) => {
    if (currentEditor.value === block.editor || !block.editor) {
      const toolbar = document.querySelector('.tiptap-toolbar')

      if (currentEditor?.value) {
        const optionElement = currentEditor.value.options.element

        if (!blockRef.value.contains(optionElement)) {
          return
        }
      }

      if (toolbar && !toolbar.contains(event.target)) {
        currentEditor.value = null
      }
    }
  })

  const setCurrentEditor = () => {
    if (block.editor) {
      currentEditor.value = block.editor
    }
  }

  const updateEditor = (editor) => {
    block.editor = editor
  }

  return {
    setCurrentEditor,
    updateEditor,
    blockRef
  }
}
