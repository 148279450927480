export function useConfirmationDialog({title, body, confirmBtnText, cancelBtnText}) {
  return window.confirmationDialog("", {
    dataset: {
      confirmTitle: title,
      confirmBody: body,
      confirmBtnText: confirmBtnText,
      cancelBtnText: cancelBtnText
    }
  })
}
