import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'question']
  static values = { id: String }

  connect() {
    if (localStorage.getItem(this.idValue)) {
      this.toggleResponses()
    }
  }

  toggleResponses(e) {
    this.toggleTarget.classList.toggle('bg-gradient-to-r')
    this.toggleTarget.classList.toggle('from-purple-500')
    this.toggleTarget.classList.toggle('to-indigo-500')
    this.toggleTarget.classList.toggle('bg-gray-200')

    const slider = this.toggleTarget.querySelector('span:last-child')

    slider.classList.toggle('translate-x-5')
    slider.classList.toggle('translate-x-0')

    this.questionTargets.forEach(question => {
      switch (question.dataset.type) {
        case 'quiz':
          this.toggleQuizResponses(question)
          break
        case 'poll':
          this.togglePollResponses(question)
          break
        case 'open':
          this.toggleOpenResponses(question)
          break
        case 'sortable':
          this.toggleTableResponses(question)
          break
      }
    })

    if (this.isToggled()) {
      localStorage.setItem(this.idValue, true)
    } else {
      localStorage.removeItem(this.idValue)
    }
  }

  isToggled() {
    return this.toggleTarget.classList.contains('to-indigo-500')
  }

  toggleQuizResponses(question) {
    this.togglePollResponses(question)

    question.querySelectorAll('[data-correct]').forEach(correct => {
      correct.classList.toggle('invisible')
    })
  }

  togglePollResponses(question) {
    question.querySelectorAll('[data-progress]').forEach(progress => {
      if (progress.dataset.toggled) {
        progress.style.width = '0%'
        progress.removeAttribute('data-toggled')
      } else {
        progress.style.width = `${progress.dataset.progress}%`
        progress.setAttribute('data-toggled', true)
      }
    })

    question.querySelectorAll('[data-count]').forEach(correct => {
      correct.classList.toggle('invisible')
    })

    question.querySelector('[data-info]')?.classList?.toggle('hidden')
  }

  toggleOpenResponses(question) {
    question.querySelector('[data-response]')?.classList?.toggle('hidden')
    question.querySelector('[data-response-selector]')?.classList?.toggle('hidden')
  }

  toggleTableResponses(question) {
    question.querySelector('[data-response-selector]')?.classList?.toggle('hidden')
    question.querySelectorAll('[data-table-cell]').forEach(cell => {
      cell.classList.toggle('text-transparent')
      cell.classList.toggle('text-gray-900')
    })
  }

  toggleOnFrameLoaded(e) {
    if (this.isToggled()) {
      const question = e.target.closest('[data-section-slide-target="question"]')

      if (question.dataset.type === 'open') {
        this.toggleOpenResponses(question)
      } else if (question.dataset.type === 'sortable') {
        this.toggleTableResponses(question)
      }
    }
  }
}