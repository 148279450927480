import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static classes = [ "star" ]
  static targets = [ "star" ]
  static values = {
    rating: Number,
  }

  connect() {
    if (this.hasStarTarget) {
      this.initForTarget()
    } else if (this.hasStarClass) {
      this.initWithClass()
    }
  }

  initForTarget() {

  }

  initWithClass() {
    const currentIndex = parseInt(this.ratingValue)
    const stars = document.querySelectorAll(`.${this.starClass}`)

    console.log(stars)
    stars.forEach((star, index) => {
      if ((index + 1) <= currentIndex) {
        star.innerHTML = this.fullStarTemplate
      } else {
        star.innerHTML = this.emptyStarTemplate
      }
    })
  }

  get fullStarTemplate() {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" data-rating class="h-6 w-6 text-yellow-500 checked" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    `
  }

  get emptyStarTemplate() {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 unchecked" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
    </svg>
    `
  }
  // static targets = ['form', 'fullStarTemplate', 'emptyStarTemplate', 'star', 'rating']
  // static values = {
  //   url: String,
  //   class: String,
  //   lesson: String
  // }

  // select(e) {
  //   const currentIndex = parseInt(e.currentTarget.dataset.index)

  //   this.starTargets.forEach(star => {
  //     if (parseInt(star.dataset.index) <= currentIndex) {
  //       star.innerHTML = this.fullStarTemplateTarget.innerHTML
  //     } else {
  //       star.innerHTML = this.emptyStarTemplateTarget.innerHTML
  //     }
  //   })

  //   // this.formTarget.classList.remove('hidden')
  //   // this.formTarget.classList.add('animate__bounceInRight')

  //   // this.ratingTarget.value = this.element.querySelectorAll('[data-rating]').length
  // }
}
