<template>
  <div
    class="absolute z-10 cursor-pointer"
    :class="wrapperClasses"
    :style="overlayStyle"
  >

  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'VideoUploadOverlay',
  props: {
    wrapperClasses: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    clipPath: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const overlayStyle = computed(() => {
      return {
        clipPath: props.clipPath,
        width: typeof props.width === 'number' ? `${props.width}px` : props.width,
        height: typeof props.height === 'number' ? `${props.height}px` : props.height,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    })

    return {
      overlayStyle
    }
  }
}
</script>
