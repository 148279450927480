<template>
  <div class="flex flex-col gap-3 my-10">
    <label for="answers" class="text-base font-medium text-gray-900 flex">Answers</label>

    <div v-for="(answer, index) in question.variant_attributes.answers_attributes" :key="index" class="flex flex-col gap-3 bg-gray-100 rounded-lg p-3 relative group">
      <button
        @click.stop="onDeleteAnswer(answer, index)"
        class="absolute top-0 mt-2 right-0 mr-2 p-1 bg-white border border-gray-300 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </button>

      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium text-gray-700 flex">
          Answer #{{index + 1}}
        </label>
        <textarea
          type="text"
          v-model="answer.text"
          @input="$emit('onInput')"
          class="border border-gray-300 rounded-md p-2"
        />
        <div v-if="errors['section.questions.variant.answers.text'] && !answer.text" class="text-red-500 text-sm">
          {{ errors['section.questions.variant.answers.text'][0] }}
        </div>
      </div>

      <div class="flex items-center">
        <input
          type="checkbox"
          class="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          @change="onCorrectChange(index)"
          v-model="answer.correct"
        >
        <label class="ms-2 text-sm font-medium text-gray-700">Correct</label>
      </div>

      <div class="flex flex-col gap-2" v-if="answer.correct">
        <label class="text-sm font-medium text-gray-700 flex">Explanation</label>
        <textarea
          type="text"
          v-model="answer.explanation"
          @input="$emit('onInput')"
          class="border border-gray-300 rounded-md p-2"
        />
      </div>
    </div>

    <button type="button" @click="addAnswer" class="py-4 px-5 mb-2 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100">
      Add answer
    </button>
  </div>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import { useConfirmationDialog } from '@/features/slides_editor/composables'

const props = defineProps({
  question: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['onInput'])
const errors = inject('errors')

const addAnswer = () => {
  props.question.variant_attributes.answers_attributes = [
    ...props.question.variant_attributes.answers_attributes,
    {
      text: "",
      correct: false,
      explanation: ""
    }
  ]
}

const onDeleteAnswer = async (answer, index) => {
  if (answer.text) {
    const confirmation = await useConfirmationDialog({
      title: 'Are you sure?',
      body: 'Are you sure you want to delete this answer? These changes cannot be undone.',
      confirmBtnText: 'Delete',
      cancelBtnText: 'Cancel'
    })

    if (confirmation) {
      props.question.variant_attributes.answers_attributes.splice(index, 1)
    }
  } else {
    props.question.variant_attributes.answers_attributes.splice(index, 1)
  }

  if (props.question.id) {
    emit('onInput')
  }
}

const onCorrectChange = (index) => {
  props.question.variant_attributes.answers_attributes.forEach((answer) => {
    answer.correct = false
  })
  props.question.variant_attributes.answers_attributes[index].correct = true

  emit('onInput')
}
</script>
