import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'lockedTemplate', 'unlockedTemplate']
  static values = {
    section: String,
    lockStatusUrl: String,
    unlockUrl: String,
    audioPath: String
  }

  connect() {
    this.checkLockStatus()
  }

  async checkLockStatus() {
    const locked = await lock.getLockStatus(this.lockStatusUrlValue, this.sectionValue)
    this.containerTarget.innerHTML = locked ? this.lockedTemplateTarget.innerHTML : this.unlockedTemplateTarget.innerHTML
  }

  async unlock() {
    await lock.unlockSection(this.unlockUrlValue, this.sectionValue)

    this.containerTarget.innerHTML = this.unlockedTemplateTarget.innerHTML
  }
}