import { Controller } from '@hotwired/stimulus'

/*
Simple Controller to preview the uploaded image

1. Wrap things in a data-controller="upload-preview"
2. Add a data-upload-preview-target="image" on the image tag
3. Add a data-upload-preview-target="input" on the file input
4. Add a data-action="upload-preview#loadPreview" on the file input
5. Add a data-action="upload-preview#unset" on the remove button

*/
export default class extends Controller {
  static targets = ['image', 'input', 'unset']

  connect() {
    if (this.hasImageTarget) {
      this.initialImage = this.imageTarget.src
    }

    if (this.hasInputTarget) {
      this.initialValue = this.inputTarget.value
    }
  }

  loadPreview(e) {
    const [file] = e.currentTarget.files

    if (file) {
      const reader = new FileReader()

      reader.onload = () => {
        if (this.hasImageTarget) {
          this.imageTarget.src = reader.result
        } else {
          e.target.setAttribute('data-preview-src', reader.result)
        }

        window.dispatchEvent(new CustomEvent('previewloaded', { detail: e.target }))
      }

      reader.readAsDataURL(file)

      if (this.hasUnsetTarget) {
        this.unsetTarget.classList.remove('hidden')
      }
    }
  }

  unset(e) {
    e.preventDefault()

    this.imageTarget.src = this.initialImage
    this.inputTarget.value = this.initialValue

    this.unsetTarget.classList.add('hidden')
  }
}
