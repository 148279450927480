import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dom-search"
export default class extends Controller {
  static targets = [
    "searchable"
  ]

  search(event) {
    const query = event.target.value.toLowerCase()

    this.searchableTargets.forEach((element) => {
      const text = element.dataset.searchValue.toLowerCase()
      const match = text.includes(query)

      if (!match) {
        element.classList.add("hidden")
      } else {
        element.classList.remove("hidden")
      }
    })
  }
}
