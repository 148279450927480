import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    frame: String
  }

  go() {
    const frame = document.querySelector(`#${this.frameValue}`)
    frame.src = this.urlValue
  }

  away() {
    const frame = document.querySelector(`#${this.frameValue}`)
    frame.innerHTML = ""
  }
}