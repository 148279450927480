import { ref } from 'vue'

export const useYouTubePlayer = (block_id) => {
  const playerRef = ref(null)

  const initYouTubePlayer = (videoId, options = {}) => {
    const { start_seconds = null, end_seconds = null, thumbnail = false } = options
    const playerId = thumbnail ? `thumbnail-${block_id}` : `player-${block_id}`
    const playerElement = document.querySelector(`#${playerId} [data-youtube-target="video"]`)

    if (!playerRef.value) {
      setTimeout(() => {
        if (window.YT && YT.Player) {
          playerRef.value = new YT.Player(playerElement, {
            events: {
              onReady: (e) => {
                e.target.cueVideoById({
                  videoId: videoId,
                  startSeconds: start_seconds,
                  endSeconds: end_seconds
                })
              }
            },
            playerVars: {
              'rel': 0  // Limits recommendations to the same channel
            },
          })
        }
      }, 1000)
    } else {
      playerRef.value.cueVideoById({
        videoId: videoId,
        startSeconds: start_seconds,
        endSeconds: end_seconds
      })
    }
  }

  const getYouTubeThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/0.jpg`
  }

  return { playerRef, initYouTubePlayer, getYouTubeThumbnailUrl }
}
