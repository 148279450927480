import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["animation"]

  connect() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(...this.animationClasses)
        }
      })
    })

    observer.observe(this.element)
  }
}