import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

/*
Simple Controller to handle nested forms

1. Wrap things in a data-controller="nested-form"
2. Add a data-upload-preview-target="image" on the image tag
3. Add a data-upload-preview-target="input" on the file input
4. Add a data-action="upload-preview#loadPreview" on the file input
5. Add a data-action="upload-preview#unset" on the remove button

*/
export default class extends Controller {
  static targets = ['addItem', 'template', 'movableQuestion', 'addButton']
  static values = {
    index: String,
    allowMultiple: {
      type: Boolean,
      default: true
    }
  }

  connect() {
    this.resetUpAndDownButtons()
  }

  resetUpAndDownButtons() {
    this.movableQuestionTargets.forEach((question, index) => {
      question.querySelector('[data-move-up]')?.remove()
      question.querySelector('[data-move-down]')?.remove()

      const buttonsTemplate = question.querySelector('[data-actions] template')

      question.querySelector('[data-actions]').insertAdjacentHTML('afterbegin', buttonsTemplate.innerHTML)

      if (index === 0) {
        question.querySelector('[data-move-up]').remove()
      }

      if (index === this.movableQuestionTargets.length - 1) {
        question.querySelector('[data-move-down]').remove()
      }
    })
  }

  swapPositions(question1, question2) {
    question2.nextSibling === question1
      ? question1.parentNode.insertBefore(question2, question1.nextSibling)
      : question1.parentNode.insertBefore(question2, question1)
  }

  moveUp(e) {
    e.preventDefault()

    const question = e.target.closest('[data-movable-question]')

    if (question.previousElementSibling.dataset.movableQuestion) {
      this.swapPositions(question.previousElementSibling, question)
    }

    this.resetUpAndDownButtons()
  }

  moveDown(e) {
    e.preventDefault()

    const question = e.target.closest('[data-movable-question]')

    if (question.nextElementSibling.dataset.movableQuestion) {
      this.swapPositions(question, question.nextElementSibling)
    }

    this.resetUpAndDownButtons()
  }

  async moveToSection(e) {
    e.preventDefault()

    const question = e.target.closest('[data-movable-question]')

    const totalSections = document.querySelectorAll('[data-lesson-section]')
    const section = prompt(`What section do you want to move this question to (1 to ${totalSections.length})`)

    if (parseInt(section) > totalSections.length) {
      alert(`The section you selected doesn't exist or it hasn't been saved yet`)
    }
    if (section) {
      const sectionIndex = parseInt(section) - 1
      const sectionContainer = totalSections[sectionIndex]
      const target = sectionContainer.querySelector('[data-questions]')

      const request = new FetchRequest(
        'PATCH',
        `/madmin/questions/${question.dataset.questionId}`,
        {
          responseKind: 'json',
          body: JSON.stringify({ questionnaire_id: sectionContainer.dataset.sectionId })
        }
      )
      const response = await request.perform()

      if (response.ok) {
        question.querySelectorAll('[name]').forEach(input => {
          const name = input.name
          input.name = `lesson[sections_attributes][${sectionIndex}]${name.substring(30)}`
        })

        target.insertBefore(question, target.lastElementChild)
      }
    }
  }

  add_association(event) {
    event.preventDefault()

    const selectedTemplate = event.currentTarget.dataset.template
    const template = selectedTemplate
      ? this.templateTargets.find(t => t.dataset.type === selectedTemplate)
      : this.templateTarget

    let content = template
      .innerHTML
      .replace(new RegExp(this.indexValue, 'g'), new Date().valueOf())

    if (this.indexValue === 'SECTION_RECORD') {
      content = content.replace('SECTION_IDX', document.querySelectorAll('[data-lesson-section]').length + 1)
    }

    this.addItemTarget.insertAdjacentHTML('beforebegin', content)
    this.element.querySelectorAll(':scope > div[data-section]').forEach((li, index) => li.setAttribute('data-position', index + 1))

    if (!this.allowMultipleValue) {
      event.currentTarget.classList.add('hidden')
    }
  }

  remove_association(event) {
    event.preventDefault()

    const item = event.target.closest('[data-section]')

    if (item.querySelector(`input[name*='_destroy']`)) {
      item.querySelector(`input[name*='_destroy']`).value = 1
    }

    item.classList.add('hidden')

    if (!this.allowMultipleValue && this.hasAddButtonTarget) {
      this.addButtonTarget.classList.remove('hidden')
    }

    item.querySelectorAll("[required]").forEach(input => input.removeAttribute("required"))
  }
}
