<template>
  <div
    :id="`block-${block.id}`"
    :style="block.outer_style"
    class="sl-block absolute"
  >
    <div class="sl-block-content h-full w-full" data-inline-svg="false">
      <div :style="block.inner_style" class="h-full w-full">
        <!-- Image w/attachment -->
        <div v-if="isImageWithAttachment" class="relative h-full w-full rounded-lg overflow-hidden">
          <img :src="imageSource" class="w-full h-full object-cover" />
          <ImageUploadOverlay v-if="block.editable" :block="block" buttonClasses="pr-6 pt-6" />
        </div>

        <!-- Drawing or Shape -->
        <div style="display: contents" v-else-if="isDrawingOrShape" v-html="block.html"></div>

        <!-- Image w/o attachment -->
        <div v-else-if="isImageWithoutAttachment" class="flex h-full w-full justify-center items-center p-6">
          <img :src="block.placeholder_image_url" class="w-full h-full object-cover rounded-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useBlockHelpers } from '../../../composables/useBlockHelpers'
import ImageUploadOverlay from '../../images/ImageUploadOverlay.vue'

export default {
  name: 'AbsoluteBlock',
  components: {
    ImageUploadOverlay
  },
  props: {
    block: {
      type: Object,
      required: true
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isImageWithAttachment, isImageWithoutAttachment, isDrawingOrShape } = useBlockHelpers(props.block)

    const imageSource = computed(() => {
      return props.isThumbnail ? props.block.static_image_url : props.block.image_url
    })

    return {
      isImageWithAttachment,
      isImageWithoutAttachment,
      isDrawingOrShape,
      imageSource
    }
  }
};
</script>
