import { Application } from "@hotwired/stimulus"
import { Modal, Dropdown, Tabs } from "tailwindcss-stimulus-components"
import RailsNestedForm from '@stimulus-components/rails-nested-form'
import { Autocomplete } from "stimulus-autocomplete"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register('tabs', Tabs)
application.register('tailwind-modal', Modal)
application.register('dropdown', Dropdown)
application.register("autocomplete", Autocomplete)
application.register("rails-nested-form", RailsNestedForm)

export { application }
