<template>
  <button @click="addSlide" :class="classes">
    <slot>
    </slot>
  </button>
</template>

<script>
import { useSlideCreate } from '../../composables/useSlideCreate'
import { inject } from 'vue'
export default {
  name: 'AddSlideButton',
  props: {
    position: {
      type: Number,
      required: true
    },
    classes: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const { createSlide } = useSlideCreate()
    const createPath = inject('createPath')

    const addSlide = () => {
      const formData = new FormData()
      formData.append('slide[position]', props.position)

      createSlide(createPath, formData)
    }

    return {
      addSlide
    }
  }
}
</script>
