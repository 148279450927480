<template>
  <p>{{ status }}</p>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'SaveStatus',
  setup() {
    const status = inject('saveStatusMessage')

    return {
      status
    }
  }
}
</script>
