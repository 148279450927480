<template>
  <div class="thumbnail-item w-full relative aspect-[16/9]">
    <section
      :class="[
        'thumbnail-wrapper absolute top-0 left-0 w-full h-full overflow-hidden border border-gray-300',
        textContrastClass(slide.layout.background_color)
      ]"
      :style="{ background: slide.layout.background_color }"
    >
      <Slide
        :key="`thumbnail-${slide.id}`"
        :slide="slide"
        :is-thumbnail="true"
      />
    </section>
    <slot name="top-left"></slot>
  </div>
</template>

<script>
import Slide from '../slides/Slide.vue'
import { textContrastClass } from '@/helpers'

export default {
  name: 'SlideThumbnail',
  components: {
    Slide
  },
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      textContrastClass
    }
  }
}
</script>

<style scoped>
.thumbnail-wrapper :deep(.actual-slide) {
  transform: scale(0.128);
  transform-origin: top left;
  width: calc(100% / 0.128);
  height: calc(100% / 0.128);
  pointer-events: none;
}
</style>
