import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['labels', 'headers', 'grid', 'header_template', 'label_template', 'cell_template']

  connect() {
    this.gridClass = null
  }

  getElementWithCorrectName(htmlString, target, index) {
    const parser = new DOMParser()
    const html = parser.parseFromString(htmlString, 'text/html')
    
    html.querySelector(target).name = `${html.querySelector(target).name}[${index}]`

    return html.body.innerHTML
  }

  generateTable() {
    const headers = parseInt(this.headersTarget.value)
    const labels = parseInt(this.labelsTarget.value)

    this.gridTarget.classList.remove(this.gridClass)
    this.gridClass = `grid-cols-${headers + 1}`
    this.gridTarget.classList.add(this.gridClass)

    let counter = 0

    this.gridTarget.innerHTML = `
      <div class="col-span-1">
      </div>

      ${Array(headers).fill().map((item, i) => {
        return this.getElementWithCorrectName(this.header_templateTarget.innerHTML, 'input', i)
      }).join('')}

      
      ${Array(labels).fill().map((item, i) => {
        const cells = []

        cells.push(this.getElementWithCorrectName(this.label_templateTarget.innerHTML, 'input', i))

        Array(headers).fill().forEach((item) => {
          cells.push(this.getElementWithCorrectName(this.cell_templateTarget.innerHTML, 'textarea', counter))
          counter++
        })

        return cells
      }).flat().join('')}
    `
  }
}