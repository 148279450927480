<template>
  <div class="border-[3px]" :class="classes" :data-controller="disabledWith ? 'tippy' : null" :data-tippy-content="disabledWith" data-tippy-placement="top-start">
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: "hover:border-[#8ee5fb]"
  },
  selected: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  disabledWith: {
    type: String,
    default: null
  }
})


const classes = computed(() => {
  const classes = [ props.color ]

  if (props.disabled) {
    classes.push('border-transparent cursor-not-allowed')
  } else {
    classes.push('cursor-pointer')
    classes.push(props.selected ? 'outline outline-[3px] outline-[#9ca3af]' : 'border-transparent')
  }

  return classes
})

</script>
