<template>
  <form @submit.prevent="submitForm" class="h-full pb-6">
    <Toolbar v-if="editor" :editor="editor" :shared="false" :excluded-items="excludedToolbarItems" />
    <div class="h-full pt-3 pb-10 trix-content">
      <ContentEditor
        :content="prepOverview"
        :onUpdate="submitForm"
        :within-slide="false"
        @editor-created="updateEditor"
      />
    </div>
  </form>
</template>

<script>
import { ref, inject } from 'vue'
import { useSlideUpdate } from '../../composables/useSlideUpdate'
import ContentEditor from '../editor/ContentEditor.vue'
import Toolbar from '../editor/Toolbar.vue'

export default {
  name: 'PrepForm',
  components: {
    ContentEditor,
    Toolbar
  },
  props: {
    updateUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const prepOverview = inject('prepOverview')
    const editor = ref(null)

    const excludedToolbarItems = ['heading_2', 'heading_3', 'heading_4', 'align_left', 'align_center', 'align_right']

    const { updateSlide } = useSlideUpdate()

    const submitForm = async () => {
      try {
        await updateSlide(props.updateUrl, { lesson_version: { prep_overview_html: editor.value.getHTML() } })
        prepOverview.value = editor.value.getHTML()
      } catch (error) {
        console.error('Error updating prep overview:', error)
      }
    }

    const updateEditor = (prepEditor) => {
      editor.value = prepEditor
    }

    return {
      submitForm,
      updateEditor,
      editor,
      prepOverview,
      excludedToolbarItems
    }
  }
}
</script>

<style scoped>
.tiptap-editable {
  @apply h-full overflow-y-scroll;
}

.tiptap-editable:hover:not(:focus-within) {
  @apply cursor-text;
}

</style>
