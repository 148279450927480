import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['details', 'default']

  toggleDetails(e) {
    const initiator = e.currentTarget
    const {target, toggled} = initiator.dataset

    if (this.hasDefaultTarget) {
      this.defaultTarget.classList.add('hidden')

      this.detailsTargets.forEach(target => {
        target.classList.add('hidden')
      })
    }


    if (target) {
      const el = this.detailsTargets
        .find(detail => detail.dataset.target === target)

      el.classList.toggle(el.dataset.toggleClass || 'hidden')
    } else {
      this.detailsTargets.forEach(detail => detail.classList.toggle(detail.dataset.toggleClass || 'hidden'))

      if (toggled) {
        initiator.dataset.toggled = ""
        initiator.innerText = initiator.dataset.showText || 'Reveal'
      } else {
        initiator.dataset.toggled = "1"
        initiator.innerText = initiator.dataset.hideText || 'Hide'
      }
    }
  }
}