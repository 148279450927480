import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String
  }

  connect() {
    // click the element
    document.querySelector(`#${this.targetValue}`).click()

    // clean the url
    history.replaceState({}, "", window.location.pathname)
  }
}