<template>
  <label
    :for="`image-upload-${block.id}`"
    class="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-300 cursor-pointer"
    :class="wrapperClasses"
  >
    <div class="absolute top-0 right-0 z-10 editable-image" :class="buttonClasses">
      <div
        class="relative inline-flex items-center justify-center text-sm leading-4 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 group text-white focus:ring-indigo-500 p-3 rounded-md w-fit"
        :class="{
          'cursor-not-allowed bg-indigo-600 hover:bg-indigo-400': isSaving,
          'cursor-pointer bg-indigo-600 hover:bg-indigo-700': !isSaving
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        </svg>
        {{ isSaving ? 'Updating...' : 'Update' }}
      </div>
      <input
        :id="`image-upload-${block.id}`"
        type="file"
        accept="image/*"
        class="hidden"
        @change="handleImageUpdate"
        :disabled="isSaving"
      />
    </div>
    <div class="w-full h-full rounded-md z-20" data-editable-image></div>
  </label>
</template>

<script>
import { inject } from 'vue'
import { useBlockUpdate } from '../../composables/useBlockUpdate'

export default {
  name: 'ImageUploadOverlay',
  props: {
    block: {
      type: Object,
      required: true
    },
    wrapperClasses: {
      type: String,
      default: ''
    },
    buttonClasses: {
      type: String,
      default: 'pr-12 pt-12'
    }
  },
  setup(props) {
    const isSaving = inject('isSaving')
    const { onBlockUpdate } = useBlockUpdate(props.block)

    const handleImageUpdate = async (event) => {
      const file = event.target.files[0]
      if (file) {
        await onBlockUpdate(file, 'image')
      }
    }

    return {
      isSaving,
      handleImageUpdate
    }
  }
}
</script>
