import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  ARIA_ATTRIBUTES_TYPES = {
    expanded: { type: Boolean },
  }

  static values = {
    attributes: Array
  }

  connect() {
    useClickOutside(this)
  }

  toggle() {
    this.attributesValue.forEach((attribute) => {
      const attributeConfig = this.ARIA_ATTRIBUTES_TYPES[attribute]
      if (!attributeConfig) {
        console.error(`Unsupported attribute: ${attribute}`)
        return
      }

      switch (attributeConfig.type) {
        case Boolean:
          this.toggleBoolean(attribute)
          break
      }
    })
  }

  toggleBoolean(attribute) {
    const value = this.element.getAttribute("aria-" + attribute)

    this.element.setAttribute(
      'aria-' + attribute,
      value === 'true' ? 'false' : 'true'
    )
  }

  clickOutside() {
    this.element.setAttribute('aria-expanded', 'false')
  }
}
