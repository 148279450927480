<template>
  <div v-if="isThumbnail" :id="`thumbnail-${block.id}`" class="h-full">
    <div
      :class="`flex items-center max-w-[${CONSTANTS.YOUTUBE_SHORTS.MAX_WIDTH}] h-[${CONSTANTS.YOUTUBE_SHORTS.HEIGHT}] overflow-hidden rounded-lg aspect-[9/16] w-fit mx-auto`"
      data-provider="youtube_shorts"
    >
      <img
        :src="getYouTubeThumbnailUrl(getVideoId())"
        :alt="block.title"
        class="w-full h-full object-cover"
      />
    </div>
  </div>

  <div v-else :id="`player-${block.id}`" class="h-full">
    <div
      :class="`flex items-center max-w-[${CONSTANTS.YOUTUBE_SHORTS.MAX_WIDTH}] h-[${CONSTANTS.YOUTUBE_SHORTS.HEIGHT}] overflow-hidden rounded-lg aspect-[9/16] w-fit mx-auto`"
      data-provider="youtube_shorts"
    >
      <div data-youtube-target="video"></div>
    </div>
  </div>
</template>

<script setup>
import { toRef, watch, onMounted, defineProps } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'
import { useYouTubePlayer } from '../../composables/useYouTubePlayer'

const props = defineProps({
  block: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  },
})

const embedRef = toRef(props.block, 'embed')

const { CONSTANTS } = useEmbedHelpers(embedRef)
const { initYouTubePlayer, getYouTubeThumbnailUrl } = useYouTubePlayer(props.block.id)

const getVideoId = () => {
  return (
    props.block.embed.external_embed_id ||
    new URL(props.block.embed.original_url).pathname.split('/').pop()
  )
}

onMounted(() => {
  if (props.isThumbnail) return

  if (window.YT) {
    initYouTubePlayer(getVideoId(), { thumbnail: props.isThumbnail })
  } else {
    console.error('YouTube API not loaded')
  }
})

watch(
  () => props.block.embed.original_url,
  () => {
    initYouTubePlayer(getVideoId(), { thumbnail: props.isThumbnail })
  }
)
</script>
