import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['disabled']

  connect() {
    this.disable()
  }

  disable() {
    this.submitButton.disabled = true
    this.submitButton.classList.add(this.disabledClass)
  }

  enable() {
    this.submitButton.disabled = false
    this.submitButton.classList.remove(this.disabledClass)
  }

  get submitButton() {
    return this.element.querySelector('[type="submit"]')
  }
}
