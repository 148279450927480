<template>
  <form @submit.prevent="submitForm" @input="dirty = true">
    <div>
      <label for="text" class="text-sm font-medium text-gray-700 flex">
        Select a Spark
      </label>

      <select v-model="selectedSpark" @change="submitForm" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
        <option v-for="spark in parsedSparks" :value="spark.id">{{ spark.title }}</option>
      </select>
    </div>
  </form>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useBlockUpdate, useSidePanelHelpers } from '@/features/slides_editor/composables'

const props = defineProps({
  block: {
    type: Object,
    required: true
  }
})
const parsedSparks = inject('parsedSparks')
const selectedSpark = ref(props?.block?.section?.spark?.id)
const { onBlockUpdate } = useBlockUpdate(props.block, { showErrorStatus: false })
const { replaceInStack, popFromStack } = useSidePanelHelpers()

const submitForm = async () => {
  const sectionParams = {
    id: props.block.section.id,
    section_spark_attributes: {
      spark_id: selectedSpark.value
    }
  }

  await onBlockUpdate(sectionParams, 'section_attributes')

  popFromStack()
  replaceInStack({
    fixed: true,
    content: {
      component: 'SparkForm',
      title: 'Edit spark',
      props: { block: props.block }
    }
  })
}
</script>

<style>
  textarea {
    field-sizing: content;
  }
</style>
