import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="iframe"
export default class extends Controller {
  connect() {
    // post message to parent window
    window.parent.postMessage({
      type: "set_height",
      value: document.body.offsetHeight
    }, "*")
  }
}
