export const QUESTION_VARIANTS = {
  poll: {
    label: 'Poll',
    icon: 'PresentationChartBarIcon',
    border_color: "border-yellow-300",
    border_hover_color: "hover:border-yellow-600",
    background_color: "bg-yellow-100",
    icon_background_color: "bg-yellow-200",
    text_color: "text-yellow-800"
  },
  quiz: {
    label: 'Quiz',
    icon: 'CheckCircleIcon',
    border_color: "border-green-300",
    border_hover_color: "hover:border-green-600",
    background_color: "bg-green-100",
    icon_background_color: "bg-green-200",
    text_color: "text-green-800"
  },
  sortable: {
    label: 'Table',
    icon: 'TableCellsIcon',
    border_color: "border-indigo-300",
    border_hover_color: "hover:border-indigo-600",
    background_color: "bg-indigo-100",
    icon_background_color: "bg-indigo-200",
    text_color: "text-indigo-800",
    disabled: true
  },
  open: {
    label: 'Open',
    icon: 'PencilIcon',
    border_color: "border-indigo-300",
    border_hover_color: "hover:border-indigo-600",
    background_color: "bg-indigo-100",
    icon_background_color: "bg-indigo-200",
    text_color: "text-indigo-800"
  }
}
