import { inject } from 'vue'
import { destroy } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useSlideDestroy() {
  const removeSlide = inject('removeSlide')
  const { setStatus, setStatusToLastSaved, setIsSaving } = useSaveStatus()

  const destroySlide = async (destroyPath, formData) => {
    const startTime = Date.now()
    setStatus('Saving...')
    setIsSaving(true)

    try {
      const response = await destroy(destroyPath, { body: formData, responseKind: 'json' })

      if (response.ok) {
        await handleSuccessfulDestroy(response, startTime)
      } else {
        handleDestroyError(await response.json)
      }
    } catch (error) {
      handleDestroyError(error)
      throw error
    }
  }

  const handleSuccessfulDestroy = async (response, startTime) => {
    const slideData = await response.json
    removeSlide(slideData.id)

    const elapsedTime = Date.now() - startTime
    const remainingTime = Math.max(0, 1000 - elapsedTime)
    setTimeout(setStatusToLastSaved, remainingTime)
    setIsSaving(false)
  }

  const handleDestroyError = (error) => {
    console.error('Error destroying slide:', error)
    setStatus('⚠️ Error saving')
    setIsSaving(false)
  }

  return { destroySlide }
}
