import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'input', 'yearLevelCount', 'teacherCount', 'courseCount', 'checkbox', 'grid', 'spinner']

  connect() {
    this.selected = []

    window.addEventListener('turbo:frame-load', (e) => {
      this.preselectOnLoad()
      this.updateCounters()

      this.spinnerTarget.classList.add('hidden')
    })
  }

  disconnect() {
    localStorage.removeItem('selectedClasses')
  }

  hideGrid() {
    this.gridTarget.classList.add('hidden')
  }

  updateCounters() {
    setTimeout(() => {
      const url = new URL(window.location.href)

      const yearLevel = url.searchParams.getAll('year_level[]')
      const teacherCount = url.searchParams.getAll('teacher[]')
      const sequenceCount = url.searchParams.getAll('sequence[]')

      if (yearLevel.length) {
        const span = this.yearLevelCountTarget.querySelector('span')
        span.innerText = yearLevel.length
        span.classList.remove('hidden')
      } else {
        this.yearLevelCountTarget.querySelector('span').classList.add('hidden')
      }

      if (teacherCount.length) {
        const span = this.teacherCountTarget.querySelector('span')
        span.innerText = teacherCount.length
        span.classList.remove('hidden')
      } else {
        this.teacherCountTarget.querySelector('span').classList.add('hidden')
      }

      if (sequenceCount.length) {
        const span = this.courseCountTarget.querySelector('span')
        span.innerText = sequenceCount.length
        span.classList.remove('hidden')
      } else {
        this.courseCountTarget.querySelector('span').classList.add('hidden')
      }
    }, 100);
  }

  uncheckAll() {
    this.checkboxTargets.forEach(box => box.checked = false)
  }

  preselectOnLoad() {
    if (localStorage.getItem('selectedClasses')) {
      const selected = JSON.parse(localStorage.getItem('selectedClasses'))
      this.selected = selected

      selected.forEach(c => {
        const input = document.querySelector(`input[data-id="${c}"]`)

        if (input) {
          input.checked = true
        }
      })

      this.updateButton()
    }
  }

  toggleSelect(e) {
    const id = e.currentTarget.dataset.id

    if (this.selected.includes(id)) {
      this.selected = this.selected.filter(item => item !== id)
    } else {
      this.selected.push(id)
    }

    localStorage.setItem('selectedClasses', JSON.stringify(this.selected))

    this.updateButton()
  }

  cleanInputs() {
    document.querySelectorAll('[data-search-input]').forEach(input => {
      input.remove()
    })
  }

  addInputs(e) {
    this.cleanInputs()

    this.selected.forEach(id => {
      const template = this.inputTargets.find(t => t.dataset.id === id)

      e.currentTarget.closest('form').insertAdjacentHTML('beforeend', template.innerHTML)
    })
  }

  updateButton() {
    const pluralized = this.selected.length === 1 ? 'class' : 'classes'

    this.buttonTarget.innerText = `Join ${pluralized}`
    this.buttonTarget.disabled = !this.selected.length
  }

  checkAdjacentFilter(e) {
    const span = e.currentTarget
    const checkbox = span.previousElementSibling

    checkbox.checked = !checkbox.checked
  }
}
