import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filterCount', 'checkbox', 'spinner', 'frame']

  static values = {
    filters: Array,
    turboFrameId: String
  }

  connect() {
    window.addEventListener('turbo:frame-load', (e) => {
      this.updateCounters()

      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.add('hidden')
      }
    })
  }

  updateCounters() {
    setTimeout(() => {
      const url = this.turboFrame && this.turboFrame.getAttribute('src')
        ? new URL(this.turboFrame.getAttribute('src'))
        : new URL(window.location.href)

      this.filtersValue.forEach((filter) => {
        const params = url.searchParams.getAll(`${filter}[]`)
        const target = this.filterCountTargets.find((t) => t.dataset.filter === filter)

        if (params.length && target) {
          const span = target.querySelector('span')
          span.innerText = params.length
          span.classList.remove('hidden')
          span.classList.remove('absolute')
          span.classList.remove('opacity-0')
        } else {
          target.querySelector('span')?.classList?.add('hidden')
        }
      })
    }, 100)
  }

  uncheckAll() {
    this.checkboxTargets.forEach(box => box.checked = false)
  }

  get turboFrame() {
    if (this.hasFrameTarget) {
      return this.frameTarget
    }

    if (this.hasTurboFrameIdValue) {
      return document.querySelector(`turbo-frame#${this.turboFrameIdValue}`)
    }
  }
}
