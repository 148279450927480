import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import pica from 'pica'
import { getMetaValue, findElement, removeElement, insertAfter } from "../helpers"

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["input", "preview", "label"]
  static values = {
    url: String
  }

  connect() {
    this.dropZone = this.createDropZone()
    this.defaultValue()
    this.bindEvents()
    this.inputTarget.addEventListener('click', (e) => e.preventDefault())
  }

  defaultValue() {
    if (this.inputTarget.dataset.defaultValue) {
      const mockFile = { name: "blabla", size: 12345 }
      this.dropZone.emit("addedfile", mockFile)
      this.dropZone.emit("thumbnail", mockFile, this.inputTarget.dataset.defaultValue)
      this.labelTarget.classList.add('sr-only')
      this.inputTarget.removeAttribute('required')
    } else if (this.inputTarget.dataset.required == "true") {
      this.inputTarget.setAttribute('required', 'required')
    }
  }

  createDropZone() {
    return new Dropzone(this.element, {
      url: this.url,
      previewTemplate: this.previewTarget.innerHTML,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      clickable: this.labelTarget,
      autoQueue: false
    })
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start()
      }, 500)

      this.labelTarget.classList.add('sr-only')

      const list = new DataTransfer()
      list.items.add(file)

      this.inputTarget.files = list.files

      this.inputTarget.removeAttribute('required')
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
      this.inputTarget.value = ''
      this.labelTarget.classList.remove('sr-only')

      if (this.inputTarget.dataset.required) {
        this.inputTarget.setAttribute('required', 'required')
      }
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })
  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles() { return this.data.get("acceptedFiles") }

  get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }

  get form() { return this.element.closest("form") }

  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }
}

class DirectUploadController {
  constructor(source, file) {
    this.source = source
    this.file = file
  }

  start() {
    this.resizeImage(this.file)
      .then(resizedFile => {
        this.directUpload = createDirectUpload(resizedFile, this.source.url, this)
        this.file.controller = this
        this.hiddenInput = this.createHiddenInput()
        this.directUpload.create((error, attributes) => {
          if (error) {
            removeElement(this.hiddenInput)
            this.emitDropzoneError(error)
          } else {
            this.hiddenInput.value = attributes.signed_id
            this.emitDropzoneSuccess()
          }
        })
      })
      .catch(error => {
        // Handle error in resizing
        console.error('Error resizing file:', error)
      })
  }

  resizeImage(file) {
    if (file.type === "image/gif") {
      return Promise.resolve(file)
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (readerEvent) => {
        const image = new Image()
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const maxSize = 800 // Example: maximum width or height
          let { width, height } = image

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width
              width = maxSize
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height
              height = maxSize
            }
          }

          canvas.width = width
          canvas.height = height

          pica().resize(image, canvas)
            .then(resizedCanvas => pica().toBlob(resizedCanvas, file.type, 0.90))
            .then(blob => {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now()
              })
              resolve(resizedFile)
            })
            .catch(reject)
        }
        image.src = readerEvent.target.result
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  // Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100

    if (progress < 100) {
      findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
    } else {
      findElement(this.file.previewTemplate, ".dz-upload").classList.add('hidden')
    }
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  })
}