import { Controller } from '@hotwired/stimulus'
import Reveal from 'reveal.js'
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js'
import { FetchRequest } from '@rails/request.js'
import {hideAll} from 'tippy.js'

export default class extends Controller {
  static targets = ["element"]

  connect() {
    this.elementTargets.forEach(element => {
      if (element.dataset.expanded == "true") {
        element.classList.remove('hidden')
      } else {
        element.setAttribute("data-expanded", "false")
      }
    })
  }

  toggle(event) {
    const current = event.target.closest('[data-either-or-target="element"]')
    
    if (current.dataset.expanded == "true") {
      current.dataset.expanded = "false"
      current.classList.add('hidden')
    }

    this.elementTargets.forEach(element => {
      if (element != current) {
        element.classList.toggle('hidden')
      }
    })
  }
}