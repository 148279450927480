import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['video']
  static values = {
    id: String,
    start: String,
    end: String
  }

  connect() {
    this.initPlayer()
  }

  initPlayer() {
    setTimeout(() => {
      if (window.YT && YT.Player) {
        new YT.Player(this.videoTarget, {
          events: {
            onReady: (e) => {
              e.target.cueVideoById({
                videoId: this.idValue,
                startSeconds: this.startValue,
                endSeconds: this.endValue
              })
            }
          }
        })
      }
    }, 1000)
  }
}