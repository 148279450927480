import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "label"]

  connect() {
    this.toggle()
    this.boundToggle = this.toggle.bind(this)
    document.addEventListener('turbo:render', this.boundToggle)
  }

  disconnect() {
    document.removeEventListener('turbo:render', this.boundToggle)
  }

  toggle() {
    if (this.inputTarget.value.trim() === "") {
      this.labelTarget.classList.remove('hidden')
      this.inputTarget.classList.add('text-transparent')
    } else {
      this.labelTarget.classList.add('hidden')
      this.inputTarget.classList.remove('text-transparent')
    }
  }
}
