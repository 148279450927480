import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'currentResponse', 'response', 'option', 'checkmark']
  static values = {question: String}

  connect() {
    if (this.hasOptionTarget) {
      this.optionTargets.forEach(option => {
        option.addEventListener('mouseenter', () => {
          option.classList.add('text-white', 'bg-indigo-600')
          option.classList.remove('text-gray-900')
        })

        option.addEventListener('mouseleave', () => {
          option.classList.add('text-gray-900')
          option.classList.remove('text-white', 'bg-indigo-600')
        })
      })
    }
    this.setActiveOnLoad()
  }

  setActiveOnLoad() {
    const currentId = localStorage.getItem(this.questionValue)
    
    if (currentId) {
      const current = document.querySelector(`template[data-id="${currentId}"]`)

      this.responseTarget.innerHTML = current.innerHTML
      this.responseTarget.dataset.currentPosition = current.dataset.position
      this.currentResponseTarget.innerText = current.dataset.label || current.dataset.position
    }
  }

  saveCurrentState(currentElement) {
    localStorage.setItem(this.questionValue, currentElement.dataset.id)
  }

  next() {
    const currentPosition = parseInt(this.responseTarget.dataset.currentPosition)
    const currentIndex = currentPosition > 0 ? currentPosition - 1 : 0

    const next = this.templateTargets[currentIndex + 1]
    
    this.checkmarkTargets.forEach(c => c.classList.add('hidden'))
    this.optionTargets.forEach(o => o.querySelector('span').classList.remove('font-semibold'))

    let activeIndex

    if (next) {
      this.responseTarget.innerHTML = next.innerHTML
      this.responseTarget.dataset.currentPosition = currentPosition + 1
      this.currentResponseTarget.innerText = next.dataset.label || currentPosition + 1

      activeIndex = currentPosition
      this.saveCurrentState(next)
    } else {
      this.responseTarget.innerHTML = this.templateTargets[0].innerHTML
      this.responseTarget.dataset.currentPosition = 1
      this.currentResponseTarget.innerText = this.templateTargets[0].dataset.label || 1

      activeIndex = 0
      this.saveCurrentState(this.templateTargets[0])
    }

    this.activateOption(this.optionTargets[activeIndex])
  }

  previous() {
    const currentPosition = parseInt(this.responseTarget.dataset.currentPosition)
    const currentIndex = currentPosition > 0 ? currentPosition - 1 : 0

    const previous = this.templateTargets[currentIndex - 1]

    this.checkmarkTargets.forEach(c => c.classList.add('hidden'))
    this.optionTargets.forEach(o => o.querySelector('span').classList.remove('font-semibold'))
    
    let activeIndex
    if (previous) {
      this.responseTarget.innerHTML = previous.innerHTML
      this.responseTarget.dataset.currentPosition = currentPosition - 1
      this.currentResponseTarget.innerText = previous.dataset.label || currentPosition - 1

      activeIndex = currentPosition - 2
      this.saveCurrentState(previous)
    } else {
      this.responseTarget.innerHTML = this.templateTargets[this.templateTargets.length - 1].innerHTML
      this.responseTarget.dataset.currentPosition = this.templateTargets.length
      this.currentResponseTarget.innerText = this.templateTargets[this.templateTargets.length - 1].dataset.label || this.templateTargets.length
      
      activeIndex = this.templateTargets.length - 1
      this.saveCurrentState(this.templateTargets[this.templateTargets.length - 1])
    }

    this.activateOption(this.optionTargets[activeIndex])
  }

  select(e) {
    const selected = e.currentTarget
    const {position} = selected.dataset

    const prevSelected = selected.closest('ul').querySelector('[data-selected]')
    if (prevSelected) {
      this.deactivateOption(prevSelected)
    }

    this.activateOption(selected)

    this.responseTarget.innerHTML = this.templateTargets[position - 1].innerHTML
    this.responseTarget.dataset.currentPosition = position
    this.currentResponseTarget.innerText = this.templateTargets[position - 1].dataset.label || position
  
    this.saveCurrentState(this.templateTargets[position - 1])
  }

  activateOption(option) {
    if (this.hasOptionTarget) {
      option.setAttribute('data-selected', true)
      option.querySelector('span').classList.remove('font-normal')
      option.querySelector('span').classList.add('font-semibold')
      option.querySelector('[data-checkmark]').classList.remove('hidden')
      option.classList.add('pr-9')
    }
  }

  deactivateOption(option) {
    if (this.hasOptionTarget) {
      option.removeAttribute('data-selected')
      option.querySelector('span').classList.add('font-normal')
      option.querySelector('span').classList.remove('font-semibold')
      option.querySelector('[data-checkmark]').classList.add('hidden')
      option.classList.remove('pr-9')
    }
  }
}
