import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ['modal', 'replaceText', 'title', 'body']

  connect() {
    super.connect()
    this.currentButton = null
  }

  replaceText(e) {
    const text = e.currentTarget.dataset.replaceText

    if (this.hasReplaceTextTarget) {
      this.replaceTextTarget.innerText = text
    }
  }

  replaceTitle(e) {
    const text = e.currentTarget.dataset.title

    if (this.hasTitleTarget) {
      this.titleTarget.innerText = text
    }
  }

  replaceBody(e) {
    const text = e.currentTarget.dataset.body

    if (this.hasBodyTarget) {
      this.bodyTarget.innerText = text
    }
  }

  open(e) {
    this.currentButton = e.target
    super.open(e)
  }

  close(e) {
    super.close(e)

    if (e.currentTarget.dataset.cancel === undefined) {
      this.currentButton.closest('form').dispatchEvent(new CustomEvent('submit', {bubbles: true}))
      this.currentButton = null
    }
  }
}
