import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['spinner', 'replace']
  static values = {
    hideText: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    if (this.element.tag === 'BUTTON' || this.element.type === 'submit') {
      this.form = this.element.closest('form') || document.querySelector(`#${this.element.getAttribute('form')}`)

      this.form.addEventListener("submit", () => {
        this.element.setAttribute("data-loading", "true")
      })

      document.addEventListener('turbo:submit-end', (e) => {
        this.element.removeAttribute("data-loading")
      })
    }
  }

  show(e) {
    if (this.hasReplaceTarget) {
      this.replaceTarget.classList.add('hidden')
    }

    if (this.hideTextValue) {
      this.spinnerTarget.parentElement.style.color = 'transparent'
    }

    this.spinnerTarget.classList.remove('hidden')
  }

  hide() {
    this.spinnerTarget.classList.add('hidden')
  }
}
