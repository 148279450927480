export const DIRECTION_MAPPINGS = {
  row: 'flex-row',
  column: 'flex-col'
}

export const ALIGN_MAPPINGS = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end'
}

export const JUSTIFY_MAPPINGS = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
  between: 'justify-between',
  around: 'justify-around'
}

export const PADDING_MAPPINGS = {
  top: {
    0: 'pt-0',
    1: 'pt-1',
    2: 'pt-2',
    3: 'pt-3',
    4: 'pt-4',
    5: 'pt-5',
    6: 'pt-6',
    8: 'pt-8',
    10: 'pt-10',
    12: 'pt-12',
    16: 'pt-16',
    20: 'pt-20',
    24: 'pt-24'
  },
  right: {
    0: 'pr-0',
    1: 'pr-1',
    2: 'pr-2',
    3: 'pr-3',
    4: 'pr-4',
    5: 'pr-5',
    6: 'pr-6',
    8: 'pr-8',
    10: 'pr-10',
    12: 'pr-12',
    16: 'pr-16',
    20: 'pr-20',
    24: 'pr-24'
  },
  bottom: {
    0: 'pb-0',
    1: 'pb-1',
    2: 'pb-2',
    3: 'pb-3',
    4: 'pb-4',
    5: 'pb-5',
    6: 'pb-6',
    8: 'pb-8',
    10: 'pb-10',
    12: 'pb-12',
    16: 'pb-16',
    20: 'pb-20',
    24: 'pb-24'
  },
  left: {
    0: 'pl-0',
    1: 'pl-1',
    2: 'pl-2',
    3: 'pl-3',
    4: 'pl-4',
    5: 'pl-5',
    6: 'pl-6',
    8: 'pl-8',
    10: 'pl-10',
    12: 'pl-12',
    16: 'pl-16',
    20: 'pl-20',
    24: 'pl-24'
  }
}
