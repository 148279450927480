import plugin, { TurboMount } from "turbo-mount/vue";
import { registerComponents } from "turbo-mount/registerComponents/vite";

const controllers = import.meta.glob("./**/*_controller.js", { eager: true });
const components = import.meta.glob("/components/**/*.vue", { eager: true });

const turboMount = new TurboMount();

registerComponents({
  plugin,
  turboMount,
  components,
  controllers
});
