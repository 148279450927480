import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch as railsPatch } from "@rails/request.js"

export default class extends Controller {
  connect() {
    this.initializeAllSortables()
  }

  initializeAllSortables() {
    const sortableElements = document.querySelectorAll(
      "[data-controller='nested-sortable']"
    )

    sortableElements.forEach((element) => {
      new NestedSortable(element)
    })
  }
}

class NestedSortable {
  constructor(element) {
    this.element = element
    this.initSortable()
  }

  initSortable() {
    new Sortable(this.element, {
      filter: ".disable-sorting",
      group: "nested",
      scrollSensitivity: 200,
      forceFallback: true,
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.3,
      onEnd: this.handleSortableEnd.bind(this),
    })
  }

  async handleSortableEnd({ item, newIndex, to }) {
    const containerId = this.getDatasetAttribute(to, "containerId")
    const containerType = this.getDatasetAttribute(to, "containerType")
    const entryId = this.getDatasetAttribute(item, "entryId")
    const entryType = this.getDatasetAttribute(item, "entryType")
    const url = this.getDatasetAttribute(item, "sortableUpdateUrl")

    if (!url) {
      console.warn("Sortable update URL is missing.")
      return
    }

    const data = new FormData()
    data.append("entry_id", entryId)
    data.append("entry_type", entryType)
    data.append("container_id", containerId)
    data.append("container_type", containerType)
    data.append("position", newIndex + 1)

    try {
      await railsPatch(url, { body: data, responseKind: "turbo-stream" })
    } catch (error) {
      console.error(
        "An error occurred while updating the sortable item:",
        error
      )
    }
  }

  getDatasetAttribute(element, attributeName) {
    if (!element || !element.dataset) {
      console.error(
        `Element or dataset is undefined. Cannot get attribute: ${attributeName}`
      )
      return null
    }
    return element.dataset[attributeName] || null
  }
}
