import { Controller } from '@hotwired/stimulus'
import Reveal from 'reveal.js'
import { FetchRequest } from '@rails/request.js'
import { current } from 'tailwindcss/colors'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [
    'field',
    'sidebar',
    'sidebarContent',
    'form',
    'slide',
    'slides',
    'appendButton',
    'prependButton',
    'sync'
  ]

  connect() {
    this.open = false
    this.initReveal()
    this.setupActionsDataProperties()
  }

  async initReveal() {
    this.deck = new Reveal({
      hash: true,
      slideNumber: 'c/t',
      progress: false
    })

    await this.deck.initialize({
      width: 1280,
      height: 720,
      maxScale: 0.8,
      help: false
    })

    this.updateAddPosition()

    this.deck.on('slidechanged', (event) => {
      this.closeSidebar()
      this.updateAddPosition()
    })
  }

  updateAddPosition() {
    if (this.slideTargets.length) {
      const appendPosition = parseInt(this.currentToolbar.dataset.position) + 1
      const prependPosition = parseInt(this.currentToolbar.dataset.position) - 1

      const appendUrl = new URL(this.appendButtonTarget.href)
      appendUrl.searchParams.set('position', appendPosition)
      appendUrl.searchParams.set('positioning_action', 'append')
      this.appendButtonTarget.href = appendUrl.toString()

      const prependUrl = new URL(this.prependButtonTarget.href)
      prependUrl.searchParams.set('position', prependPosition)
      prependUrl.searchParams.set('positioning_action', 'prepend')
      this.prependButtonTarget.href = prependUrl.toString()
    } else {
      const appendUrl = new URL(this.appendButtonTarget.href)
      appendUrl.searchParams.set('position', 1)
      appendUrl.searchParams.set('positioning_action', 'append')
      this.appendButtonTarget.href = appendUrl.toString()

      const prependUrl = new URL(this.prependButtonTarget.href)
      prependUrl.searchParams.set('position', 1)
      prependUrl.searchParams.set('positioning_action', 'prepend')
      this.prependButtonTarget.href = prependUrl.toString()
    }
  }

  setupActionsDataProperties() {
    this.fields.forEach((field, index) => {
      field.element.setAttribute('data-action', 'click->slides-editor#edit')
      field.element.setAttribute('data-field-index', index)
    })
  }

  closeSidebar() {
    this.open = false

    this.sidebarTarget.classList.remove('lg:w-1/3', 'hidden')
    this.sidebarTarget.classList.add('lg:w-0', 'hidden')

    this.sidebarContentTarget.innerHTML = ''
  }

  liveUpdate(event) {
    let input, value

    if (event.type === "dropzone:complete") {
      input = event.srcElement.querySelector('input[data-name]')
      value = event.detail.args[0].dataURL
    } else if (event.type === "trix-change") {
      input = event.srcElement
      value = `<div class="trix-content">${input.value}</div>`
    } else if (event.type === "previewloaded") {
      input = event.detail
      value = input.dataset.previewSrc
    } else {
      input = event.target
      value = input.value
    }

    const field = this.currentSlide.querySelector(`[data-field="${input.dataset.name}"]`)

    if (!field) return

    if (field.dataset.type === 'image') {
      field.querySelector('img').src = value
    } else if (field.dataset.type === 'embed' && input.dataset.content === 'url') {
      if (value.includes('youtube.com/shorts')) {
        const url = new URL(value)
        const id = url.pathname.split('/').pop()

        this.addYoutubeEmbed(field, id)
      } else if (value.includes('youtube.com')) {
        const url = new URL(value)
        const id = url.searchParams.get('v')

        this.addYoutubeEmbed(field, id)
      }
    } else if (field.dataset.type === 'embed' && input.dataset.content === 'start') {
      this.addYoutubeEmbed(field, this.youtube.id, value, this.youtube.end)
    } else if (field.dataset.type === 'embed' && input.dataset.content === 'end') {
      this.addYoutubeEmbed(field, this.youtube.id, this.youtube.start, value)
    } else if (field.dataset.type === 'embed') {
      field.querySelector('img').src = value
    } else if (field.dataset.type === 'title') {
      field.querySelector(':scope *:not(:has(*))').innerText = value
    } else if (field.dataset.type === 'section' && input.dataset.content === 'title') {
      field.querySelector('[data-title]').innerText = value
    } else if (field.dataset.type === 'section' && input.dataset.content === 'subtitle') {
      field.querySelector('[data-subtitle]').innerText = value
    } else {
      field.innerHTML = value
    }
  }

  async swapPrev(event) {
    event.preventDefault()

    const position = parseInt(this.currentToolbar.dataset.position)
    const previous = parseInt(this.currentToolbar.dataset.previous)

    if (previous) {
      event.currentTarget.closest('form').requestSubmit()
      this.swapSlides(position, previous)
      this.deck.prev()
      this.deck.sync()
    }
  }

  async swapNext(event) {
    event.preventDefault()

    const position = parseInt(this.currentToolbar.dataset.position)
    const next = parseInt(this.currentToolbar.dataset.next)

    if (next) {
      event.currentTarget.closest('form').requestSubmit()
      this.swapSlides(position, next)
      this.deck.next()
      this.deck.sync()
    }
  }

  swapSlides(current_position, new_position) {
    const slides = this.slideTargets
    const parent = this.slidesTarget

    const slide1 = this.slideTargets.find(slide => parseInt(slide.dataset.position) == current_position)
    const slide2 = this.slideTargets.find(slide => parseInt(slide.dataset.position) == new_position)

    const temp = slide1.innerHTML
    slide1.innerHTML = slide2.innerHTML
    slide2.innerHTML = temp

    this.swapBackgrounds(slide1, slide2)
  }

  swapBackgrounds(slide1, slide2) {
    const placeholder = document.createElement('div');
    const background1 = this.deck.getSlideBackground(slide1)
    const background2 = this.deck.getSlideBackground(slide2)

    background1.parentNode.insertBefore(placeholder, background1);
    background2.parentNode.insertBefore(background1, background2);
    placeholder.parentNode.insertBefore(background2, placeholder);
    placeholder.parentNode.removeChild(placeholder);

    const dataBackground1 = slide1.dataset.backgroundColor
    const dataBackground2 = slide2.dataset.backgroundColor

    slide1.setAttribute('data-background-color', dataBackground2)
    slide2.setAttribute('data-background-color', dataBackground1)
  }

  addYoutubeEmbed(element, videoId, startSeconds, endSeconds) {
    this.youtube = {
      ...this.youtube,
      id: videoId,
      start: startSeconds,
      end: endSeconds
    }

    element.innerHTML = `
      <div
        class="relative w-full h-full flex justify-center overflow-hidden px-10"
        data-provider="youtube"
        data-controller="youtube"
        data-youtube-start-value="${startSeconds || ''}"
        data-youtube-end-value="${endSeconds || ''}"
        data-youtube-id-value="${videoId}">
        <div class="w-full h-full rounded-lg" data-youtube-target="video"></div>
      </div>
    `
  }

  tagPossibleLayoutChange() {
    this.currentSlide.setAttribute("data-new-layout", true)
  }

  async slideTargetConnected(slide) {
    if (slide.dataset.newSlide) {
      slide.removeAttribute('data-new-slide')
      await this.initReveal()
      this.deck.slide(parseInt(slide.dataset.position) - 1)
    } else if (slide.dataset.onlySlide) {
      slide.removeAttribute('data-only-slide')
      await this.initReveal()
    }
  }

  async slideTargetDisconnected(slide) {
    if (!this.slideTargets.length) {
      window.location.reload()
    }

    const currentIndex = this.deck.getIndices(this.currentSlide).h

    await this.initReveal()

    if (!slide.dataset.newLayout) {
      this.deck.slide(currentIndex == 0 ? 0 : currentIndex - 1)
    }
  }

  formTargetConnected() {
    window.dispatchEvent(new Event('resize'))

    if (this.formTarget.querySelector("[data-embed]")) {
      const embedForm = this.formTarget.querySelector("[data-embed]")
      const videoId = embedForm.dataset.embedId

      if (videoId) {
        const youtube = document.querySelector(`[data-youtube-id-value="${videoId}"]`)

        if (youtube) {
          this.youtube = {
            id: videoId,
            start: youtube.dataset.youtubeStartValue,
            end: youtube.dataset.youtubeEndValue
          }
        }
      }
    }
  }

  formTargetDisconnected() {
    window.dispatchEvent(new Event('resize'))
  }

  syncTargetConnected() {
    console.log("Sync....")
    this.deck.sync()
  }

  get fields() {
    return this.fieldTargets.map(field => ({
      name: field.dataset.field,
      type: field.dataset.type,
      element: field
    }))
  }

  get currentSlide() {
    return this.slideTargets.find(slide => slide.classList.contains('present'))
  }

  get currentToolbar() {
    return this.currentSlide.querySelector('[data-slides-editor-target="toolbar"]')
  }
}