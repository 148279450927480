import TextareaAutogrow from 'stimulus-textarea-autogrow'

export default class extends TextareaAutogrow {
  connect() {
    super.connect()
  }

  autogrow() {
    super.autogrow()
    this.resizeSiblings()
  }

  resizeSiblings() {
    const textareas = this.element
      .closest('tr')
      .querySelectorAll('textarea')
    
    const height = Math.max(...Array.from(textareas).map(a => a.scrollHeight))

    textareas.forEach(area => area.style.height = `${height}px`)
  }
}