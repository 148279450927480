<template>
  <div class="h-full min-h-fit relative bg-white text-gray-900 rounded-lg p-6 text-center">
    <div data-sparks-target="container" class="h-full min-h-fit">
      <div class="flex flex-col h-full justify-center items-center">
        <div class="flex justify-center items-center mb-5 gap-2">
           <img :src="spark_image_url" alt="Spark placeholder">
          <span class="text-7xl font-semibold nunito">{{ block?.section?.spark?.title || 'Spark' }}</span>
        </div>

        <p class="text-2xl text-gray-700">Sparks are an interactive quiz game which can't be previewed.</p>
        <p class="text-2xl text-gray-700">Students will be able to compete against each other to answer questions correctly and reach the top of the scoreboard.</p>
        <p class="text-2xl text-gray-700"><a target="_blank" href="https://help.wellioeducation.com/interactive-questions-in-wellio#Sparks" class="text-indigo-600">Click here</a> for a short video explaining how to play.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SparkPlaceholder',
  props: {
    block: {
      type: Object,
      required: true
    },
    spark_image_url: {
      type: String,
      required: true
    }
  },
};
</script>
