import { colorBrightness, colorToRgb } from 'reveal.js/js/utils/color'

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function textContrastClass(backgroundColor) {
  const rgb = colorToRgb( backgroundColor )
  if( rgb && rgb.a !== 0 ) {
    if( colorBrightness( backgroundColor ) < 128 ) {
      return 'has-dark-background'
    }
    return 'has-light-background'
  }
}
