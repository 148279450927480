import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['checkedText', 'uncheckedText', 'checkedBg', 'uncheckedBg']
  static targets = ['label', 'span']

  toggle(e) {
    this.uncheckAll()

    this.check(e.currentTarget)
  }

  check(input) {
    const label = input.closest('label')
    const span = label.querySelector('span')

    label.classList.add(...this.checkedBgClass.split(' '))
    label.classList.remove(...this.uncheckedBgClass.split(' '))

    span.classList.add(...this.checkedTextClass.split(' '))
    span.classList.remove(...this.uncheckedTextClass.split(' '))
  }

  uncheckAll() {
    this.labelTargets.forEach(label => {
      label.classList.remove(...this.checkedBgClass.split(' '))
      label.classList.add(...this.uncheckedBgClass.split(' '))
    })

    this.spanTargets.forEach(span => {
      span.classList.remove(...this.checkedTextClass.split(' '))
      span.classList.add(...this.uncheckedTextClass.split(' '))
    })
  }
}