import { Controller } from "@hotwired/stimulus"
import { get as railsGet, destroy as railsDestroy } from "@rails/request.js"

// Connects to data-controller="streams"
export default class extends Controller {
  static values = {
    url: String,
    responseKind: {
      type: String,
      default: "turbo-stream"
    }
  }
  static targets = ["container"]

  connect() {
    this.originalUrl = this.urlValue
  }

  async get() {
    await railsGet(this.urlValue, {
      responseKind: this.responseKindValue
    })

    this.urlValue = this.originalUrl
  }

  async destroy() {
    await railsDestroy(this.urlValue, {
      responseKind: this.responseKindValue
    })

    this.urlValue = this.originalUrl
  }

  appendValueToUrl(event) {
    const value = event.target.value
    this.urlValue = `${this.urlValue}?${event.target.dataset.name}=${value}&target=${event.target.dataset.target}`
  }
}