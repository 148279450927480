import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    text: String,
    type: {
      type: String,
      default: 'text/plain'
    },
    tooltip: {
      type: String,
      default: 'Copy shareable link'
    },
    copiedTooltip: {
      type: String,
      default: 'Link copied!'
    }
  }

  connect() {
    this.initTooltip()
  }

  copy(event) {
    event.preventDefault()

    const type = this.typeValue
    const blob = new Blob([this.textValue], { type })
    const text = new ClipboardItem({ [type]: blob })

    navigator.clipboard.write([text])
      .then(() => {
        this.tippy.setContent(this.copiedTooltipValue)
      })
  }

  initTooltip() {
    this.tippy = tippy(this.element, {
      content: this.tooltipValue,
      trigger: 'mouseenter',
      placement: 'bottom',
      theme: 'light',
      delay: [300, 0],
      hideOnClick: false,
      onHidden: () => {
        this.tippy.setContent(this.tooltipValue)
      }
    })
  }
}
