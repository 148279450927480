<template>
  <Highlighter :disabled="disabled" :disabled-with="disabledWith" :selected="selected" :class="containerClass" class="bg-gray-100 overflow-hidden shadow rounded-lg py-6 group relative" @click="onQuestionClick">
    <div class="flex mb-6 justify-between px-6 h-full">
      <div class="flex flex-col items-between w-full h-full">
        <div>
          <div class="flex w-full">
            <span class="inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium" :class="backgroundAndTextColor">
              {{ `Q${question.position_within_lesson} · ${capitalize(question.variant_name)}` }}
            </span>

            <span v-if="question.optional" class="ml-4 inline-flex justify-center items-center px-4 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <span class="h-1.5 w-1.5 bg-gray-400 rounded-full mr-2"></span>
              Extension
            </span>

            <button
              v-if="featureFlags.questions_crud"
              @click.stop="onDeleteQuestion(question)"
              class="absolute top-0 mt-3 right-0 mr-3 p-1 bg-gray-200 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>

          <div class="tag text-base text-left mt-6 ml-1 min-h-[15px]">
            <div class="trix-content" v-html="question.text"></div>
          </div>
        </div>

        <div v-if="question?.variant_name?.toLowerCase() === 'open'" class="relative bg-white flex-grow justify-between border text-gray-900 mt-6 w-full rounded-lg overflow-hidden flex flex-col">
          <div class="rounded-t-lg">
            <div class="text-base text-left overflow-y-auto h-full p-3">
              {{ question.variant_attributes?.example_answer }}
            </div>
          </div>
          <div class="h-10 border-t flex justify-between">
            <div class="flex justify-center items-center text-base text-center w-full">
              Example answer
            </div>
          </div>
        </div>

        <div v-if="question.variant_attributes?.answers_attributes?.length" class="mt-6 w-full rounded-lg border border-gray-200 divide-y divide-gray-200 overflow-hidden">
          <div
            v-if="question.variant_attributes?.answers_attributes?.length"
            v-for="(answer, index) in question.variant_attributes.answers_attributes"
            :key="index"
            class="bg-white border-gray-200 relative p-4 grid grid-cols-10 cursor-pointer"
          >
            <div class="col-span-10 flex justify-start items-center text-left">
              <span class="text-gray-900 block text-sm font-normal mr-3 break-word">
                {{ answer.text }}
              </span>

              <div class="ml-10" v-if="answer.correct">
                <div class="text-green-800 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                  <span class="ml-3 text-base">{{ answer.explanation }}</span>
                </div>
              </div>

              <div v-if="answer.static_percentage" class="absolute h-full w-0 bg-gradient-to-r from-yellow-400 to-orange-500 opacity-20 z-1 inset-0 transition-width duration-300 ease-in-out"
                :style="{ width: (answer.static_percentage || 0) + '%' }"
              ></div>
            </div>
          </div>
        </div>

        <div v-if="question.question_type === 'sortable'" class="overflow-x-auto sm:-mx-6 mt-6">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden flex sm:rounded-lg sortable-question">
              <table class="w-full divide-y divide-gray-200 table-fixed" style="flex: 6">
                <thead class="bg-gray-50">
                  <tr>
                    <th v-if="shouldShowLabels" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" :style="{ width: labelHeaderWidth }">
                    </th>

                    <th v-for="(header, index) in question.variant_attributes.headers" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(cells, index) in question.variant_attributes.cells" :key="index">
                      <td v-if="shouldShowLabels" class="p-4 whitespace-normal bg-gray-50 text-left text-sm font-medium text-gray-500 border-r border-gray-200">
                        <span>
                          {{ question.variant_attributes.labels[index] }}
                        </span>
                      </td>

                      <td v-for="(cell, index) in cells" :key="index" class="p-4 whitespace-normal break-words text-left text-sm text-gray-900 border-r border-b border-gray-200">
                        {{ cell }}
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Highlighter>
</template>

<script setup>
import { computed, inject, defineEmits } from 'vue'
import { capitalize } from '@/helpers'
import { useSidePanelHelpers, useConfirmationDialog, useBlockUpdate } from '@/features/slides_editor/composables'
import Highlighter from '@/features/slides_editor/components/slides/blocks/Highlighter.vue'
import { QUESTION_VARIANTS } from '@/features/slides_editor/config/constants/question_variants'

const props = defineProps({
  question: {
    type: Object,
    required: true
  },
  block: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['select'])

const featureFlags = inject('featureFlags')
const errors = inject('errors')

const { pushToStack, replaceInStack, popFromStack, activePanel } = useSidePanelHelpers()
const { onBlockUpdate } = useBlockUpdate(props.block)

const backgroundAndTextColor = computed(() => {
  return `${QUESTION_VARIANTS[props.question.question_type].background_color} ${QUESTION_VARIANTS[props.question.question_type].text_color}`
})

const shouldShowLabels = computed(() => {
  return props.question.variant_attributes.labels.length > 0
})

const containerClass = computed(() => {
  const classes = ['p-3']

  if (props.selected) {
    classes.push('border-gray-500')
  }

  if (props.question.question_type.toLowerCase() === 'sortable') {
    classes.push('col-span-3')
  } else {
    classes.push('col-span-1')
  }

  if (Object.keys(errors.value).length > 0) {
    classes.push('border-red-500')
  }

  return classes.join(' ')
})

const labelHeaderWidth = computed(() => {
  const longestLabel = props.question.variant_attributes.labels.reduce((longest, current) => {
    return current.length > longest.length ? current : longest
  }, '')
  const averageCharWidth = 14 * 0.6

  const width = longestLabel.split('').reduce((acc, char) => {
    if (char.match(/[A-Z]/)) {
      return acc + averageCharWidth * 1.2
    } else if (char.match(/[a-z]/)) {
      return acc + averageCharWidth
    } else if (char.match(/[!@#$%^&*()_+\-=\[\]{}':"\\|,.<>?~]/)) {
      return acc + averageCharWidth * 0.7
    } else if (char.match(/\s/)) {
      return acc + averageCharWidth * 0.5
    } else {
      return acc + averageCharWidth
    }
  }, 0)

  return `${Math.ceil(width)}px`
})

const disabled = computed(() => {
  return Object.keys(errors.value).length > 0 || props.question.variant_name.toLowerCase() === 'sortable'
})

const disabledWith = computed(() => {
  if (props.question.variant_name.toLowerCase() === 'sortable') {
    return 'Table questions cannot be edited yet'
  }

  return null
})

const onQuestionClick = () => {
  if (!featureFlags.questions_crud || disabled.value) {
    return
  }

  emit('select', props.question.id)

  const panel = {
    content: {
      title: `Q${props.question.position_within_lesson} · ${capitalize(props.question.variant_name)}`,
      titleClass: 'text-base text-gray-900 font-medium',
      component: 'QuestionForm',
      props: { question: props.question, block: props.block }
    }
  }

  if (activePanel.value.content.component === 'QuestionForm') {
    replaceInStack(panel)
  } else {
    pushToStack(panel)
  }
}

const onDeleteQuestion = async (question) => {
  const confirmation = await useConfirmationDialog({
    title: 'Are you sure?',
    body: 'Are you sure you want to delete this question? These changes cannot be undone.',
    confirmBtnText: 'Delete',
    cancelBtnText: 'Cancel'
  })

  if (confirmation) {
    const sectionParams = {
      id: props.block.section.id,
      questions_attributes: props.block.section.questions.map(q => {
        if (q.id === question.id) {
          return {
            ...q,
            _destroy: 1
          }
        }

        return q
      })
    }

    await onBlockUpdate(sectionParams, 'section_attributes')

    if (activePanel.value.content.component === 'QuestionForm' && activePanel.value.content.props.question.id === question.id) {
      popFromStack()
    }
  }
}
</script>
