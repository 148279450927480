import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']

  connect() {
    this.hiddenClasses = ['hidden', 'transform', 'opacity-0', 'scale-95']
    this.activeClasses = ['block', 'transform', 'opacity-100', 'scale-100']
  }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove(...this.hiddenClasses)
      this.cardTarget.classList.add(...this.activeClasses)
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add(...this.hiddenClasses)
      this.cardTarget.classList.remove(...this.activeClasses)
    }
  }
}