import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { with: String }

  connect() {
    this.element.dataset['action'] = 'submit->disable#disableForm turbo:submit-end->disable#enableForm turbo:submit-end->loading#hide'

    if (!this.hasWithValue) {
      this.withValue = "Processing..."
    }
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.setAttribute('data-initial-value', button.value)
      button.value = this.withValue
    })
  }

  enableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = false
      button.value = button.dataset.initialValue

      button.parentNode.querySelector('[data-loading-target="spinner"]')?.classList.add('hidden')
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }
}
