import { Controller } from '@hotwired/stimulus'
import debounce from 'debounce'

export default class extends Controller {
  static values = {
    onload: Boolean,
  }

  initialize() {
    if (this.hasOnloadValue && this.onloadValue) {
      setTimeout(() => {
        this.submit()
      }, 1500)
    }

    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300)
  }

  preventToggle(e) {
    e.currentTarget.checked = !e.currentTarget.checked
  }

  confirmBeforeSubmit(e) {
    this.preventToggle(e)

    const dialog = document.getElementById("turbo_confirm")

    const { confirmTitle, confirmBody, confirmButtonText, cancelButtonText } = e.target.dataset

    dialog.querySelector('[data-title]').innerText = confirmTitle
    dialog.querySelector('[data-body]').innerHTML = confirmBody
    dialog.querySelector('[data-confirm]').innerHTML = confirmButtonText
    dialog.querySelector('[data-cancel]').innerHTML = cancelButtonText

    dialog.classList.remove('hidden')
    dialog.showModal()
    dialog.focus()

    dialog.addEventListener("close", () => {
      if (dialog.returnValue === 'confirm') {
        e.target.checked = !e.target.checked
        this.submit(e)
      }

      dialog.classList.add('hidden')
    }, { once: true })
  }

  submit(e) {
    const form = this.element.getAttribute('form')
      ? document.querySelector(`#${this.element.getAttribute('form')}`)
      : this.element.closest('form')

    form.requestSubmit()
  }

  debouncedSubmit() {
    this.submit()
  }
}
