import { computed } from 'vue';

export function useBlockHelpers(block) {
  const parseStyleString = (styleString) => {
    if (!styleString) return {}
    return styleString.split(';').reduce((acc, style) => {
      const [key, value] = style.split(':').map(s => s.trim())
      if (key) {
        acc[key] = value
      }
      return acc
    }, {})
  }

  const isImageWithAttachment = computed(() => {
    return block.block_type === 'image' && block.image_url
  });

  const isImageWithoutAttachment = computed(() => {
    return block.block_type === 'image' && !block.image_url
  });

  const isDrawingOrShape = computed(() => {
    return block.block_type === 'drawing' || block.block_type === 'shape'
  });

  const reconcileBlocks = (currentBlocks, updatedBlocks) => {
    const updatedBlockIds = getUpdatedBlockIds(updatedBlocks)
    const mergedBlocks = deepMergeExistingBlocks(currentBlocks, updatedBlocks, updatedBlockIds)
    const newBlocks = getNewBlocks(currentBlocks, updatedBlocks)

    return [...mergedBlocks, ...newBlocks]
  }

  const getUpdatedBlockIds = (blocks) => {
    return new Set(blocks.map(block => block.id))
  }

  const deepMergeExistingBlocks = (currentBlocks, updatedBlocks, updatedBlockIds) => {
    return currentBlocks
      .filter(currentBlock => updatedBlockIds.has(currentBlock.id))
      .map(currentBlock => {
        const updatedBlock = updatedBlocks.find(block => block.id === currentBlock.id)
        return updatedBlock ? { ...currentBlock, ...updatedBlock } : currentBlock
      });
  }

  const getNewBlocks = (currentBlocks, updatedBlocks) => {
    return updatedBlocks.filter(block => !currentBlocks.some(currentBlock => currentBlock.id === block.id))
  }

  return {
    parseStyleString,
    isImageWithAttachment,
    isImageWithoutAttachment,
    isDrawingOrShape,
    reconcileBlocks
  }
}
