<template>
  <EditorContent
    v-if="editor"
    :editor="editor"
    class="tiptap-editable"
    :class="{ 'is-empty': isEmpty }"
    :data-within-slide="withinSlide"
  />
</template>

<script>
import { EditorContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import debounce from 'debounce'

import { onBeforeUnmount, onMounted, watch, computed } from 'vue'

export default {
  name: 'ContentEditor',
  props: {
    content: {
      type: String,
      required: true
    },
    withinSlide: {
      type: Boolean,
      default: true
    },
    onUpdate: {
      type: Function,
      required: false
    },
    forcedTag: {
      type: String,
      default: null
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditorContent
  },
  setup(props, { emit }) {
    const debouncedUpdate = debounce((html) => {
      props.onUpdate(html)
    }, 1000)

    const generateContent = (content, forcedTag) => {
      return forcedTag ? `<${forcedTag}>${content}</${forcedTag}>` : content
    }

    const editor = useEditor({
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        })
      ],
      content: generateContent(props.content, props.forcedTag),
      onUpdate: ({ editor }) => {
        debouncedUpdate(editor.getHTML())
      }
    })

    const isEmpty = computed(() => {
      if (!editor.value) return true
      return !editor.value.state.doc.textContent.trim().length && !props.isThumbnail
    })

    onMounted(() => {
      emit('editor-created', editor.value)
    })

    onBeforeUnmount(() => {
      editor.value.destroy()
    })

    watch(() => props.content, (newContent) => {
      if (props.isThumbnail) {
        editor.value.commands.setContent(generateContent(newContent, props.forcedTag))
      }
    })

    return { editor, isEmpty }
  }
}
</script>

<style scoped>
  .tiptap-editable {
    @apply relative rounded;
    border: 1px solid transparent;

    &::before {
      content: '';
      inset: -8px -16px;
      border: 3px solid transparent;

      @apply pointer-events-none transition-colors duration-200 absolute z-20 rounded-lg;
    }

    &:hover:not(:focus-within) {
      @apply cursor-pointer;
    }

    &[data-within-slide="true"] {
      &:hover:not(:focus-within)::before,
      &:has(.tiptap.ProseMirror.ProseMirror-focused)::before {
        border-color: #8ee5fb;
      }

      &.is-empty::before {
        content: 'Click to add text';
        @apply border-gray-300 flex items-center pl-4 text-gray-300 font-light;
      }

      &:has(.tiptap.ProseMirror.ProseMirror-focused)::before {
        content: '';
      }
    }

    &[data-within-slide="false"] {
      @apply mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
    }
  }
</style>
