import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Connects to data-controller="prefetch"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    window.Turbo = Turbo
    this._prefetch()
  }

  _prefetch() {
    if (this.hasUrlValue) {
      Turbo.fetch(this.urlValue, {
        headers: {
          "VND.PREFETCH": "true",
          "Accept": "text/html, application/xhtml+xml"
        }
      })
    }
  }
}
