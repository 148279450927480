import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ['modal']

  connect() {
    super.connect()
    this.exitButton = document.querySelector('.exit-slide')
    this.attachListener()
  }

  attachListener() {
    this.exitButton.addEventListener('click', (e) => {
      e.preventDefault()
      
      const studentsEl = document.querySelector('[data-student-list]')

      if (!studentsEl) {
        window.location.href = this.exitButton.href
      }

      let students = JSON.parse(studentsEl.dataset.studentList)
      students = students.map(([firstName, lastName]) => `${firstName} ${lastName}`)

      if (students.length) {
        document.querySelector('[data-students-count]').innerText = students.length
        document.querySelector('[data-students-names]').innerText = students.join(', ')
        super.open(e)
      } else {
        window.location.href = this.exitButton.href
      }
    })
  }

  close(e) {
    if (e.currentTarget.dataset.cancel === undefined) {
      window.location.href = this.exitButton.href
    } else {
      super.close(e)
    }
  }
}
