import { inject } from 'vue'
import { post } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useSlideCreate() {
  const addSlide = inject('addSlide')
  const { setStatus, setStatusToLastSaved, setIsSaving } = useSaveStatus()

  const createSlide = async (createPath, formData) => {
    const startTime = Date.now()
    setStatus('Saving...')
    setIsSaving(true)

    try {
      const response = await post(createPath, { body: formData, responseKind: 'json' })

      if (response.ok) {
        await handleSuccessfulCreate(response, startTime)
      } else {
        handleCreateError(await response.json)
      }
    } catch (error) {
      handleCreateError(error)
      throw error
    }
  }

  const handleSuccessfulCreate = async (response, startTime) => {
    const slideData = await response.json
    addSlide(slideData.slide)

    const elapsedTime = Date.now() - startTime
    const remainingTime = Math.max(0, 1000 - elapsedTime)
    setTimeout(setStatusToLastSaved, remainingTime)
    setIsSaving(false)
  }

  const handleCreateError = (error) => {
    console.error('Error creating slide:', error)
    setStatus('⚠️ Error saving')
    setIsSaving(false)
  }

  return { createSlide }
}
