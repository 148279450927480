
    // app/javascript/controllers/auto_save_controller.js
    import { Controller } from "@hotwired/stimulus"
    
    const LEAVING_PAGE_MESSAGE = "You have unsaved changes. Are you sure you want to leave this page?"

    export default class extends Controller {
      static targets = ["form"]

      formTargetConnected() {
        this.formTarget.addEventListener("change", this.changed.bind(this))
        this.formTarget.addEventListener("trix-change", this.changed.bind(this))
        this.formTarget.addEventListener("submit", this.allowFormSubmission.bind(this))
      }

      changed(event) {
        this.setChanged("true")
      }
    
      leavingPage(event) {
        if (this.isFormChanged()) {
          if (!window.confirm(LEAVING_PAGE_MESSAGE)) { 
            event.preventDefault()
          } else {
            this.setChanged("false")
          }
        } 
      }
    
      allowFormSubmission(event) {
        this.setChanged("false")
      }
    
      setChanged(changed) {
        this.data.set("changed", changed)
      }
    
      isFormChanged() {
        return this.data.get("changed") == "true";
      }
    }
  