<template>
  <div v-if="errors['section.questions.variant.base']" class="bg-red-50 p-2 rounded-lg text-red-500 mb-3">
    <span class="text-sm">{{ errors['section.questions.variant.base'][0] }}</span>
  </div>

  <form @submit.prevent="submitForm" @input="dirty = true">
    <div>
      <label for="text" class="text-sm font-medium text-gray-700 flex">
        Question
      </label>

      <textarea
        v-model="question.text"
        @input="debouncedSubmit"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <div v-if="errors['section.questions.text']" class="text-red-500 text-sm">{{ errors['section.questions.text'][0] }}</div>
    </div>

    <component
      :is="variantComponent(question.variant_name)"
      :question="question"
      @on-input="debouncedSubmit"/>

    <div v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</div>
  </form>
</template>

<script>
import { ref, inject, watch, onBeforeUnmount } from 'vue'
import debounce from 'debounce'
import { VariantOpenFields, VariantPollFields, VariantQuizFields } from './'
import { useQuestionHelpers, useBlockUpdate, useSaveStatus, useSidePanelHelpers } from '@/features/slides_editor/composables'

const VARIANT_TO_COMPONENT = {
  open: "VariantOpenFields",
  poll: "VariantPollFields",
  quiz: "VariantQuizFields"
}

export default {
  name: 'QuestionForm',
  props: {
    question: {
      type: Object,
      required: true
    },
    block: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VariantOpenFields,
    VariantPollFields,
    VariantQuizFields
  },
  setup(props) {
    const editor = ref(null)
    const errorMessage = ref('')
    const dirty = ref(false)
    const initialState = { ...props.question }

    const { onAfterCreate } = useQuestionHelpers()
    const slides = inject('parsedSlides')
    const errors = inject('errors')

    const { onBlockUpdate } = useBlockUpdate(props.block, { showErrorStatus: false })
    const { resetErrors } = useSaveStatus()
    const { fixSidePanel, unfixSidePanel } = useSidePanelHelpers()

    const submitForm = async () => {
      const sectionParams = {
        id: props.block.section.id,
        questions_attributes: props.block.section.questions
      }

      const response = await onBlockUpdate(sectionParams, 'section_attributes')

      if (response && !props.question.id) {
        onAfterCreate(slides, response)
      }
    }

    const debouncedSubmit = debounce(submitForm, 500)
    const variantComponent = (variantType) => VARIANT_TO_COMPONENT[variantType.toLowerCase()]

    watch(errors, (newErrors) => {
      if (Object.keys(newErrors).length > 0) {
        fixSidePanel()
      } else {
        unfixSidePanel()
      }
    }, { deep: true })

    onBeforeUnmount(() => {
      if (Object.keys(errors.value).length > 0 && !dirty.value) {
        if (props.isNew) {
          props.block.section.questions.pop()
        } else {
          Object.assign(props.question, initialState)
        }

        resetErrors()
      }

    })

    return {
      editor,
      errors,
      debouncedSubmit,
      variantComponent,
      errorMessage
    }
  }
}
</script>

<style>
  textarea {
    field-sizing: content;
  }
</style>
