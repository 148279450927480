import { inject } from 'vue'
import { patch } from '@rails/request.js'
import { useSaveStatus } from './useSaveStatus'

export function useBlockUpdate(block, options = {}) {
  const { setStatus, setIsSaving, setStatusToLastSaved, setErrors, resetErrors } = useSaveStatus()
  const showErrorStatus = options.showErrorStatus ?? true

  const updateSlides = inject('updateSlides')

  const updateBlock = async (updatePath, body, headers = { 'Content-Type': 'application/json' }, responseKind = 'json') => {
    const startTime = Date.now()

    setStatus('Saving...')
    setIsSaving(true)

    try {
      const response = await patch(updatePath, {
        body,
        headers,
        responseKind
      })

      if (response.ok) {
        resetErrors()
        return handleSuccessfulUpdate(response, startTime)
      } else {
        handleUpdateError(await response.json)
      }
    } catch (error) {
      handleUpdateError(error)
      throw error
    }
  }

  const handleSuccessfulUpdate = async (response, startTime) => {
    const parsedResponse = await response.json

    updateSlides(parsedResponse.slide)

    const elapsedTime = Date.now() - startTime
    const remainingTime = Math.max(0, 1000 - elapsedTime)

    setTimeout(setStatusToLastSaved, remainingTime)
    setIsSaving(false)

    return parsedResponse
  }

  const handleUpdateError = (error) => {
    console.error('Error updating block:', error)

    if (showErrorStatus) {
      setStatus('⚠️ Error saving')
    } else {
      setStatus('')
    }

    setErrors(error.errors)
    setIsSaving(false)
  }

  const makeParams = (content, field) => {
    // This is a hack I'm not proud of.
    // There's no full support for sortable questions yet, meaning that
    // saving their full content will break them
    // instead we only process the position and keep the rest as is
    if (content.questions_attributes) {
      content.questions_attributes = content.questions_attributes.map((question) => {
        if (question.variant_name.toLowerCase() === 'sortable') {
          return {
            position: question.position
          }
        }

        return question
      })
    }

    const parts = field.split('.')

    let params = {}
    let current = params

    parts.forEach((key, index) => {
      if (index === parts.length - 1) {
        current[key] = content
      } else {
        current[key] = {}
        current = current[key]
      }
    })

    return { block: params }
  }

  const onBlockUpdate = async (content, field) => {
    if (content instanceof File) {
      const data = new FormData()
      data.append(`block[${field}]`, content)

      return updateBlock(block.update_path, data, {})
    } else {
      return updateBlock(block.update_path, makeParams(content, field))
    }
  }

  return {
    onBlockUpdate
  }
}
