<template>
  <div class="flex flex-col text-sm justify-center relative items-center" data-type="section">
    <div class="h3 font-extrabold z-200 text-center min-w-[400px] mb-2" ref="blockRef">
      <ContentEditor
        :content="section.title"
        :onUpdate="(html) => updateSectionText(html, 'section_title')"
        forcedTag="h3"
        :is-thumbnail="isThumbnail"
        class="before:justify-center"
      />
    </div>

    <div class="p w-2/3 font-light z-200 text-center" style="margin: 15px 0 30px 0;">
      <ContentEditor
        :content="section.subtitle"
        :onUpdate="(html) => updateSectionText(html, 'section_subtitle')"
        forcedTag="div"
        class="before:justify-center"
        :is-thumbnail="isThumbnail"
      />
    </div>
  </div>

  <div
    class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center py-10 pb-20 relative"
    data-questions
    ref="questionsContainer"
  >
    <Question
      v-for="(question) in section.questions"
      :key="question.id"
      :question="question"
      :selected="selectedQuestion === question.id"
      @select="selectedQuestion = question.id"
      :block="block"
    />
  </div>
</template>

<script setup>
import Sortable from "sortablejs"
import { ref, onMounted } from 'vue'
import Question from '../questions/Question.vue'
import ContentEditor from '../../editor/ContentEditor.vue'
import { useSidePanelHelpers, useBlockUpdate, useContentEditor } from '@/features/slides_editor/composables'

const props = defineProps({
  section: {
    type: Object,
    required: true
  },
  block: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  }
})

const { activePanel, popFromStack } = useSidePanelHelpers()

const selectedQuestion = ref(null)

const { blockRef } = useContentEditor(props.block)
const { onBlockUpdate } = useBlockUpdate(props.block)

const questionsContainer = ref(null)

const updateSectionText = (html, field) => {
  if (activePanel.value.content.component === 'QuestionForm') {
    popFromStack()
  }

  onBlockUpdate(html, field)
}

onMounted(() => {
  new Sortable(questionsContainer.value, {
    animation: 150,
    forceFallback: true,
    onEnd: ({ oldIndex, newIndex }) => {
      const questions = props.section.questions
      const question = questions[oldIndex]

      questions.splice(oldIndex, 1)
      questions.splice(newIndex, 0, question)

      props.section.questions = questions.map((question, index) => ({
        ...question,
        position: index + 1
      }))

      const sectionParams = {
        id: props.section.id,
        questions_attributes: props.section.questions
      }

      onBlockUpdate(sectionParams, 'section_attributes')
    },
  })
})
</script>
