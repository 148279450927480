<template>
  <div class="tiptap-toolbar" :data-shared="shared">
    <div class="flex button-group" :class="{ 'gap-1': currentEditor }">
      <div class="flex items-center gap-3">
        <button class="flex toolbar-button" v-if="shared" @click=openLayoutClick>
          <RectangleGroupIcon class="h-5 w-5" />
          <span class="text-sm ml-1">Layout</span>
        </button>

        <div class="relative" v-if="shared && !school?.device_free">
          <button class="flex toolbar-button" @click="deviceFreeDropdownOpen = !deviceFreeDropdownOpen">
            <DeviceTabletIcon class="h-5 w-5" />

            <span class="text-sm ml-1 mr-2">Device-free</span>

            <span v-if="currentSlide?.device_free_compatible">
              <div class="size-1 bg-indigo-600 rounded-full"></div>
            </span>
            <span v-else>
              <div class="size-1 bg-gray-300 rounded-full"></div>
            </span>
          </button>

          <div v-if="deviceFreeDropdownOpen"
            ref="deviceFreeDropdown"
            class="absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-3">
            <div class="flex items-center justify-between">
              <div data-controller="tippy" data-tippy-content="When this is enabled, this slide will be shown when presenting the lesson in device-free mode. You can disable it if you believe the content of the slide requires students to have their devices.">
                <InformationCircleIcon class="h-5 w-5 text-gray-400" />
              </div>

              <span class="text-sm text-gray-700">
                Show in device-free mode
              </span>
              <div class="relative">
                <button type="button"
                  @click="toggleDeviceFree"
                  :class="{ 'bg-indigo-600': currentSlide.device_free_compatible, 'bg-gray-200': !currentSlide.device_free_compatible }"
                  class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false">
                  <span class="sr-only">Use setting</span>

                  <span :class="{ 'translate-x-5': currentSlide.device_free_compatible, 'translate-x-0': !currentSlide.device_free_compatible }" class="pointer-events-none relative inline-block size-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
                    <span :class="{ 'opacity-0 duration-100 ease-out': currentSlide.device_free_compatible, 'opacity-100 duration-200 ease-in': !currentSlide.device_free_compatible }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in" aria-hidden="true">
                      <svg class="size-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>

                    <span :class="{ 'opacity-100 duration-200 ease-in': currentSlide.device_free_compatible, 'opacity-0 duration-100 ease-out': !currentSlide.device_free_compatible }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-100 ease-out" aria-hidden="true">
                      <svg class="size-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="relative" v-if="shared && !school?.device_free">
          <button class="flex toolbar-button" @click="studentViewSettingsDropdownOpen = !studentViewSettingsDropdownOpen">
            <AcademicCapIcon class="h-5 w-5" />
            <span class="text-sm ml-1">Student view settings</span>
          </button>

          <div v-if="studentViewSettingsDropdownOpen"
            ref="studentViewSettingsDropdown"
            class="absolute left-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-3 flex flex-col gap-3">
            <div class="flex items-center justify-between" :class="{ 'opacity-50 pointer-events-none': !hasText }">
              <span class="text-sm text-gray-700">Show text</span>
              <div class="relative">
                <button type="button"
                  @click="toggleStudentViewSettings('show_text')"
                  :class="{ 'bg-indigo-600': currentSlide.student_view_settings.show_text, 'bg-gray-200': !currentSlide.student_view_settings.show_text }"
                  class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false">
                  <span class="sr-only">Use setting</span>

                  <span :class="{ 'translate-x-5': currentSlide.student_view_settings.show_text, 'translate-x-0': !currentSlide.student_view_settings.show_text }" class="pointer-events-none relative inline-block size-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
                    <span :class="{ 'opacity-0 duration-100 ease-out': currentSlide.student_view_settings.show_text, 'opacity-100 duration-200 ease-in': !currentSlide.student_view_settings.show_text }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in" aria-hidden="true">
                      <svg class="size-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>

                    <span :class="{ 'opacity-100 duration-200 ease-in': currentSlide.student_view_settings.show_text, 'opacity-0 duration-100 ease-out': !currentSlide.student_view_settings.show_text }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-100 ease-out" aria-hidden="true">
                      <svg class="size-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            </div>

            <div class="flex items-center justify-between" :class="{ 'opacity-50 pointer-events-none': !hasImages }">
              <span class="text-sm text-gray-700">Show images</span>
              <div class="relative">
                <button type="button"
                  @click="toggleStudentViewSettings('show_images')"
                  :class="{ 'bg-indigo-600': currentSlide.student_view_settings.show_images, 'bg-gray-200': !currentSlide.student_view_settings.show_images }"
                  class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false">
                  <span class="sr-only">Use setting</span>

                  <span :class="{ 'translate-x-5': currentSlide.student_view_settings.show_images, 'translate-x-0': !currentSlide.student_view_settings.show_images }" class="pointer-events-none relative inline-block size-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
                    <span :class="{ 'opacity-0 duration-100 ease-out': currentSlide.student_view_settings.show_images, 'opacity-100 duration-200 ease-in': !currentSlide.student_view_settings.show_images }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in" aria-hidden="true">
                      <svg class="size-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>

                    <span :class="{ 'opacity-100 duration-200 ease-in': currentSlide.student_view_settings.show_images, 'opacity-0 duration-100 ease-out': !currentSlide.student_view_settings.show_images }" class="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-100 ease-out" aria-hidden="true">
                      <svg class="size-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentEditor" class="inline-block h-full w-[1px] self-stretch bg-gray-200 mx-3"></div>

      <div v-if="currentEditor" class="flex gap-3">
        <button v-for="item in toolbarItems" :key="item.name" class="flex toolbar-button" @click="item.action(currentEditor)" :class="{ 'is-active': item.isActive(currentEditor) }">
          <component v-if="item.component" :is="components[item.component]" class="h-4 w-4" />
          <span v-else v-html="item.icon"></span>
        </button>
      </div>
      <div v-else></div>
    </div>

    <div v-if="shared" class="text-sm text-gray-600 ml-4 flex items-center h-6">
      <SaveStatus />
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import SaveStatus from './SaveStatus.vue'
import { RectangleGroupIcon, BoldIcon, ItalicIcon,
  StrikethroughIcon, ListBulletIcon, NumberedListIcon,
  H1Icon, H2Icon, H3Icon, ArrowUturnLeftIcon,
  ArrowUturnRightIcon, DeviceTabletIcon, AcademicCapIcon,
  Bars3Icon, Bars3BottomLeftIcon, Bars3BottomRightIcon,
  InformationCircleIcon, ComputerDesktopIcon
} from '@heroicons/vue/24/outline'
import { TOOLBAR_ITEMS } from '@/helpers/constants/toolbar_items'
import { useSidePanelHelpers, useSlideUpdate } from '../../composables'

const props = defineProps({
  shared: {
    type: Boolean,
    default: true
  },
  editor: {
    type: Object,
    default: null
  },
  excludedItems: {
    type: Array,
    default: []
  }
})

const components = {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  ListBulletIcon,
  NumberedListIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  Bars3Icon,
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  ComputerDesktopIcon,
  AcademicCapIcon,
  DeviceTabletIcon
}

const currentEditor = props.editor || inject('currentEditor')
const { replaceInStack } = useSidePanelHelpers()
const parsedLayouts = inject('parsedLayouts')
const toolbarItems = TOOLBAR_ITEMS.filter(item => !props.excludedItems.includes(item.name))
const currentSlide = inject('currentSlide')
const school = inject('school')

const studentViewSettingsDropdownOpen = ref(false)
const studentViewSettingsDropdown = ref(null)
const deviceFreeDropdownOpen = ref(false)
const deviceFreeDropdown = ref(null)

const { updateSlide } = useSlideUpdate()

const openLayoutClick = () => {
  replaceInStack({
    content: {
      title: 'Choose a layout',
      component: 'LayoutForm',
      props: { layouts: parsedLayouts.value }
    }
  })
}

onClickOutside(studentViewSettingsDropdown, _ => studentViewSettingsDropdownOpen.value = false)
const toggleStudentViewSettings = (field) => {
  updateSlide(currentSlide.value.update_path, { slide: {
    student_view_setting_attributes: { ...currentSlide.value.student_view_settings, [field]: !currentSlide.value.student_view_settings[field] }
  }})
}

onClickOutside(deviceFreeDropdown, _ => deviceFreeDropdownOpen.value = false)
const toggleDeviceFree = () => {
  updateSlide(currentSlide.value.update_path, { slide: { device_free_compatible: !currentSlide.value.device_free_compatible } })
}

const editableBlocks = computed(() => currentSlide.value.blocks.filter(block => block.editable))
const hasText = computed(() => editableBlocks.value.some(block => ['section', 'text'].includes(block.block_type)))
const hasImages = computed(() => editableBlocks.value.some(block => block.block_type === 'image'))

</script>

<style scoped>
  .button-group .toolbar-button {
    padding: 2px 8px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 0 1px #e0e0e0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: fit-content;

    &:hover {
      background-color: #f0f9ff;
      cursor: pointer;
    }

    &.is-active {
      font-weight: bold;
      background-color: #f0f9ff;
      color: #4338ca;
    }
  }

  .tiptap-toolbar[data-shared="true"] {
    .button-group .toolbar-button {
      padding: 6px 12px;
      font-size: 16px;
    }
  }

  .button-group .toolbar-button {
    &:focus {
      outline: none;
    }
  }
</style>
