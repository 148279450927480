import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bar']

  changeWidth(e) {
    const section = e.target.closest('section')
    const {totalSections, position} = section.dataset

    this.barTarget.style.width = `${(parseInt(position) + 1) / parseInt(totalSections) * 100 }%`
  }
}
