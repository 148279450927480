<template>
  <div
    ref="thumbnailContainer"
    class="flex flex-col h-full overflow-y-auto bg-gray-100 w-56 pl-3 pr-5 pb-2"
  >
    <div
      v-for="(slide, index) in slides"
      :key="slide.id"
      :data-slide-id="slide.id"
      :data-slide-move-path="slide.move_path"
      :data-index="index"
      @click="selectSlide(index)"
      class="slide-thumbnail"
    >
      <div class="thumbnail-container flex flex-col items-center relative pl-6 mb-1 cursor-pointer group"
        :class="{ 'active': index === currentSlideIndex }"
        @click="selectSlide(index)"
      >
        <div class="slide-number absolute left-0 flex items-center justify-center font-bold text-xs text-gray-500 top-[10px] -translate-y-1/2 w-[20px] h-[20px]">{{ slide.position }}</div>
        <SlideThumbnail :slide="slide">
          <template #top-left>
            <button
              @click.stop="confirmAndDestroySlide(slide.destroy_path)"
              class="absolute top-1 right-1 p-1 bg-gray-200 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </template>
        </SlideThumbnail>
      </div>

      <div class="undraggable h-5 flex items-center justify-center w-full pl-6 group">
        <AddSlideButton
          :position="index + 2"
          :classes="['flex items-center justify-center transition-opacity opacity-0 duration-300 hover:opacity-100 w-full']"
        >
          <div class="flex-grow border-t border-indigo-500"></div>
          <span class="px-4 text-sm text-indigo-500">
            Add slide
          </span>
          <div class="flex-grow border-t border-indigo-500"></div>
        </AddSlideButton>
      </div>
    </div>

    <AddSlideButton
      :position="slides.length + 1"
      :classes="['undraggable ml-6 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']"
    >
      <div class="flex items-center justify-center h-20 border-2 border-dashed border-gray-300 text-center hover:border-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
        </svg>
      </div>
    </AddSlideButton>
  </div>
</template>

<script>
import SlideThumbnail from '../slides/SlideThumbnail.vue'
import { watch, ref, onMounted } from 'vue'
import debounce from 'debounce'
import Sortable from 'sortablejs'
import { useSlideUpdate } from '../../composables/useSlideUpdate'
import { useSlideDestroy } from '../../composables/useSlideDestroy'
import AddSlideButton from '../slides/AddSlideButton.vue'
import { useConfirmationDialog, useSidePanelHelpers } from '../../composables'

export default {
  name: 'SlidesThumbnailPanel',
  props: {
    slides: {
      type: Array,
      required: true,
    },
    currentSlideIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    SlideThumbnail,
    AddSlideButton
  },
  setup(props, { emit }) {
    const thumbnailContainer = ref(null)
    const { updateSlide } = useSlideUpdate()
    const { destroySlide } = useSlideDestroy()
    const { activePanel, popFromStack } = useSidePanelHelpers()

    const scrollToThumbnail = debounce((index) => {
      const nextThumbnail = document.querySelector(`.slide-thumbnail:nth-child(${index + 1})`)
      if (nextThumbnail) {
        nextThumbnail.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
    }, 100)

    const selectSlide = (index) => {
      emit('select-slide', index)
    }

    watch(() => props.currentSlideIndex, (newIndex) => {
      scrollToThumbnail(newIndex)
    })

    onMounted(() => {
      new Sortable(thumbnailContainer.value, {
        animation: 150,
        filter: '.undraggable',
        onEnd: async ({ item, newIndex }) => {
          const newSlides = [...props.slides];
          const [movedSlide] = newSlides.splice(item.dataset.index, 1);

          newSlides.splice(newIndex, 0, movedSlide)

          const updatedSlides = newSlides.map((slide, index) => ({
            ...slide,
            position: index + 1
          }))

          emit('update:slides', updatedSlides)

          const slideMovePath = item.dataset.slideMovePath
          await updateSlide(slideMovePath, { slide: { position: newIndex + 1 } })

          window.dispatchEvent(new Event('slides:order-updated'))
        }
      })
    })

    const confirmAndDestroySlide = async (updatePath) => {
      const confirmation = await useConfirmationDialog({
        title: 'Delete slide',
        body: 'Are you sure you want to delete this slide?',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Cancel'
      })

      if (confirmation) {
        destroySlide(updatePath)

        if (!activePanel.value.shared) {
          popFromStack()
        }
      }
    }

    return {
      selectSlide,
      thumbnailContainer,
      confirmAndDestroySlide
    }
  },
}
</script>

<style scoped>
.thumbnail-container:first-child {
  padding-top: 2px;
}

.active .thumbnail-item {
  @apply outline outline-gray-400;
  outline-width: 3px;
}

.thumbnail-container:hover .thumbnail-item {
  @apply outline;
  outline-width: 3px;
  outline-color: #8ee5fb;
}
</style>
