<template>
  <div v-if="isThumbnail" class="h-full mx-auto" :style="{ maxHeight: 'calc(100% - 2.5rem)'}">
    <img :src="getYouTubeThumbnailUrl(getVideoId())" :alt="block.title" class="w-full h-full object-cover" />
  </div>

  <div v-else :id="`player-${block.id}`" class="h-full">
    <div
      :class="youtubeWrapperClass"
      data-provider="youtube"
      :style="youtubeStyle"
    >
      <div :class="youtubeVideoClass" data-youtube-target="video"></div>
    </div>
  </div>
</template>

<script setup>
import { toRef, onMounted, watch, defineProps } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'
import { useYouTubePlayer } from '../../composables/useYouTubePlayer'

const props = defineProps({
  block: {
    type: Object,
    required: true
  },
  isThumbnail: {
    type: Boolean,
    default: false
  },
})

const embedRef = toRef(props.block, 'embed')
const { initYouTubePlayer, getYouTubeThumbnailUrl } = useYouTubePlayer(props.block.id)
const { youtubeWrapperClass, youtubeStyle, youtubeVideoClass } = useEmbedHelpers(embedRef)

const getVideoId = () => {
  return (
    props.block.embed.external_embed_id ||
    new URL(props.block.embed.original_url).searchParams.get('v')
  )
}

onMounted(() => {
  if (props.isThumbnail) return

  if (window.YT) {
    initYouTubePlayer(getVideoId(), {
      start_seconds: props.block.embed.start,
      end_seconds: props.block.embed.end,
      thumbnail: props.isThumbnail,
    })
  } else {
    console.error('YouTube API not loaded')
  }
})

watch(
  () => [
    props.block.embed.original_url,
    props.block.embed.start,
    props.block.embed.end,
  ],
  () => {
    if (!props.isThumbnail) {
      initYouTubePlayer(getVideoId(), {
        start_seconds: props.block.embed.start,
        end_seconds: props.block.embed.end,
        thumbnail: props.isThumbnail,
      })
    }
  }
)
</script>
