import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    this.toggleRequired()
    this.handleRequiredState()
  }

  handleRequiredState() {
    if (!this.hasCheckboxTarget) {
      this.toggleRequired()
      
      this.checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', this.toggleRequired.bind(this))
      })
    }
  }

  toggleRequired() {
    const checked = Array.from(this.checkboxes).find(checkbox => checkbox.checked)

    if (checked) {
      this.checkboxes.forEach(checkbox => checkbox.removeAttribute('required'))
    } else {
      this.checkboxes.forEach(checkbox => checkbox.setAttribute('required', 'required'))
    }
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggleRequired.bind(this))
  }

  get checkboxes () {
    return this.hasCheckboxTarget ? this.checkboxTargets : this.element.querySelectorAll('input[type=checkbox]')
  }
}