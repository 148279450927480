import { ref, computed } from 'vue'

const stack = ref([])
const activePanel = computed(() => stack.value[stack.value.length - 1])

export function useSidePanelHelpers() {
  const pushToStack = ({ content, shared = false, fixed = false }) => {
    stack.value.push({ content, shared, fixed })
  }

  const prependToStack = ({ content, shared = false, fixed = false }) => {
    stack.value.unshift({ content, shared, fixed })
  }

  const replaceInStack = ({ content, shared = false, fixed = false }) => {
    stack.value.pop()
    stack.value.push({ content, shared, fixed })
  }

  const removeNonSharedFromStack = () => {
    stack.value = stack.value.filter(item => item.shared)
  }

  const popFromStack = () => {
    stack.value.pop()
  }

  const closeSidePanel = () => {
    stack.value = []
  }

  const fixSidePanel = () => {
    stack.value[stack.value.length - 1].fixed = true
  }

  const unfixSidePanel = () => {
    stack.value[stack.value.length - 1].fixed = false
  }

  return {
    stack,
    pushToStack,
    popFromStack,
    replaceInStack,
    prependToStack,
    removeNonSharedFromStack,
    activePanel,
    closeSidePanel,
    fixSidePanel,
    unfixSidePanel
  }
}
