import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'quiz', 'poll', 'open', 'sortable', 'container']

  connect() {
    this.selected()
  }

  selected() {
    this.hideFields()

    switch (this.selectTarget.value) {
      case 'Question::Quiz':
        this.containerTarget.innerHTML = this.quizTarget.innerHTML
        // this.choiceTarget.classList.remove('hidden')
        break;
      case 'Question::Poll':
        this.containerTarget.innerHTML = this.pollTarget.innerHTML
        // this.choiceTarget.classList.remove('hidden')
        break;
      case 'Question::Open':
        this.containerTarget.innerHTML = this.openTarget.innerHTML
        // this.openTarget.classList.remove('hidden')
        break;
      case 'Question::Sortable':
        this.containerTarget.innerHTML = this.sortableTarget.innerHTML
        // this.sortableTarget.classList.remove('hidden')
        break;
      default:
        break;
    }
  }

  hideFields() {
    this.quizTarget.classList.add('hidden')
    this.pollTarget.classList.add('hidden')
    this.openTarget.classList.add('hidden')
    this.sortableTarget.classList.add('hidden')
  }
}
