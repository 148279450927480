import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
import { useHotkeys } from 'stimulus-use/hotkeys'

export default class extends Controller {
  static targets = ['content']

  connect() {
    useClickOutside(this, { element: this.contentTarget })
    useHotkeys(this, {
      esc: [this.close],
    })
  }

  close() {
    this.element.parentElement.removeAttribute('src')
    this.element.remove()
  }
}
