import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checked', 'wrapper']

  connect() {
    const url = new URL(window.location.href)

    const rating = url.searchParams.get('star_rating')

    if (rating) {
      this.rate({currentTarget: {
        value: rating
      }})
    }
  }

  rate(event) {
    this.wrapperTargets.forEach(wrapper => {
      const input = wrapper.querySelector('input')
      const checked = wrapper.querySelector('.checked')
      const uncheked = wrapper.querySelector('.unchecked')

      if (input.value <= event.currentTarget.value) {
        checked.classList.remove('hidden')
        uncheked.classList.add('hidden')
      } else {
        checked.classList.add('hidden')
        uncheked.classList.remove('hidden')
      }
    })
  }
}