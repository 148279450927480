import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['body']

  toggle() {
    this.bodyTarget.classList.toggle('hidden')
  }

  connect() {
    this.id = this.element.dataset.accordionId

    if (!this.id) return

    this.restoreState()
    this.element.addEventListener('toggle', this.saveState)
    document.addEventListener('turbo:render', this.restoreState)
  }

  disconnect() {
    this.element.removeEventListener('toggle', this.saveState)
    document.removeEventListener('turbo:render', this.restoreState)
  }

  saveState = () => {
    const isOpen = this.element.open
    sessionStorage.setItem(`accordion-${this.id}`, isOpen)
  }

  restoreState = () => {
    const isOpen = sessionStorage.getItem(`accordion-${this.id}`)
    if (isOpen === 'true') {
      this.element.open = true
    } else if (isOpen === 'false') {
      this.element.open = false
    }
  }
}
