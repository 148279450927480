import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['destination', 'template']
  static values = {
    ifDifferent: String,
    onlyOnce: {
      type: Boolean,
      default: false
    }
  }

  inject() {
    if (this.onlyOnceValue && this.destinationTarget.querySelector('[data-injected-html]')) {
      return
    }
    
    this.destinationTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
    this.destinationTarget.firstElementChild.dataset.injectedHtml = true
  }

  injectIfDifferent(e) {
    if (this.ifDifferentValue != e.target.value) {
      this.inject()
    }
  }

  remove(e) {
    e.target.closest('[data-anchor]').remove()
  }

  removeIfSame(e) {
    if (this.ifDifferentValue == e.target.value) {
      this.destinationTarget.querySelector('[data-injected-html]').remove()
    }
  }
}