import { Controller } from '@hotwired/stimulus'
import Reveal from 'reveal.js'
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js'
import { FetchRequest } from '@rails/request.js'
import {hideAll} from 'tippy.js'

export default class extends Controller {
  connect() {
    this.resizeVideo()
  }

  async resizeVideo() {
    const embed = this.element.querySelector('iframe')

    // if (embed.src.includes('youtube.com')) {
    //   const videoId = embed.src.split('/embed/')[1].split('?')[0]

    //   const {height, width} = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}`)
    //     .then(res => res.json())

    //   const ratio = width / height
      
    //   const currentWidth = embed.offsetWidth
    //   embed.style.height = `${currentWidth / ratio}px`
    // }
  }
}